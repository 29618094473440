import { Modal, ModalBody, Button, ButtonToolbar, Col, Row } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { gql, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";

import { UPDATE_USER_LEAVE_INFO } from "@/GraphQL/Mutation";
import FormField from "./FormField";
import Loader from "../Account/Profile/components/Loader";
import { GET_USER_BY_USERID } from "@/GraphQL/Queries";
const LeaveUpdateForm = ({ toggle, modal, userDetails }) => {
  const userId = userDetails?.id;

  const {
    data: userDetailsData,
    loading: userLoading,
    error,
    refetch,
  } = useQuery(GET_USER_BY_USERID, {
    skip: !userId,
    variables: {
      id: userId,
    },
    onCompleted: (data) => {
      if (data?.getUserByUserid?.additionalInfo) {
        const additionalInfo = data.getUserByUserid.additionalInfo;

        // Set default values for the form fields using react-hook-form's setValue
        setValue("casual", additionalInfo.casual || 0);
        setValue("sick", additionalInfo.sick || 0);
        setValue("anual", additionalInfo.anual || 0);
        setValue("reimburse", additionalInfo.reimburse || 0);
        setValue("gender", data?.getUserByUserid?.gender);
        setValue("maternity", additionalInfo.maternity || 0);
        setValue("menstrual", additionalInfo.menstrual || 0);

        console.log("Form default values set:", additionalInfo);
      }
    },
    onError: (err) => {
      console.error("Error fetching user details:", err);
    },
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      userId: userId,
      gender: userDetailsData?.getUserByUserid?.gender,
      casual: userDetailsData?.getUserByUserid?.additionalInfo?.casual || 0,
      sick: userDetailsData?.getUserByUserid?.additionalInfo?.sick || 0,
      anual: userDetailsData?.getUserByUserid?.additionalInfo?.anual || 0,
      reimburse:
        userDetailsData?.getUserByUserid?.additionalInfo?.reimburse || 0,
      maternity:
        userDetailsData?.getUserByUserid?.additionalInfo?.maternity || 0,
      menstrual:
        userDetailsData?.getUserByUserid?.additionalInfo?.menstrual || 0,
    },
  });
  const casualValue = watch("casual", "");

  const [updateUserLeaveInfo, { loading }] = useMutation(
    UPDATE_USER_LEAVE_INFO
  );

  const onSubmit = async (values) => {
    const processedValues = {
      ...values,
      casual: Number(values.casual),
      sick: Number(values.sick),
      anual: Number(values.anual),
      reimburse: Number(values.reimburse),
      maternity: Number(values.maternity),
      menstrual: Number(values.menstrual),
    };

    try {
      const response = await updateUserLeaveInfo({
        // variables: processedValues,

        variables: {
          ...processedValues,
          userId: Number(userDetails?.id),
        },
      });

      if (response?.data?.updateUserLeaveInfo) {
        toast.success("Leave details updated successfully!");
        toggle(); // Close the modal
      } else {
        toast.error("Failed to update leave details.");
      }
    } catch (error) {
      console.error("Error updating leave info:", error);
      toast.error("An error occurred while updating leave details.");
    }
  };

  return (
    <Modal toggle={toggle} isOpen={modal}>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <Loader />
        </div>
      ) : (
        <ModalBody>
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            {/* User Info */}
            <h2 className="mb-3">Leave Update</h2>
            <Col md={12}>
              <div className="form__form-group">
                <label className="form__form-group-label">User Name</label>
                <div className="form__form-group-field">
                  <FormField
                    name="userName"
                    control={control}
                    component="input"
                    defaultValue={userDetails?.name}
                    readOnly
                  />
                </div>
              </div>
            </Col>
            {/* Leave Details */}
            {/* <Col md={12}>
              <div className="form__form-group">
                <label className="form__form-group-label">Casual Leave</label>
                <div className="form__form-group-field">
                  <FormField
                    name="casual"
                    control={control}
                    component="input"
                    type="number"
                    rules={{ required: "Casual leave is required" }}
                  />
                  {errors.casual && (
                    <span className="form__form-group-error">
                      {errors.casual.message}
                    </span>
                  )}
                </div>
              </div>
            </Col> */}

            <Col md={12}>
              <div className="form__form-group">
                <label className="form__form-group-label">Casual Leave</label>
                <div className="form__form-group-field">
                  <Controller
                    name="casual"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Casual leave is required",
                      max: {
                        value: 10, // Set maximum value
                        message: "Casual leave cannot exceed 10 days",
                      },
                    }}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="number"
                        className="form-control"
                        style={{
                          borderColor: errors.casual ? "red" : "initial",
                        }}
                      />
                    )}
                  />
                  {/* {errors.casual && (
                    <span className="form__form-group-error">
                      {errors.casual.message}
                    </span>
                  )} */}
                </div>
                {casualValue > 10 && (
                  <span className="form__form-group-error">
                    Value cannot exceed 10 days
                  </span>
                )}
              </div>
            </Col>
            <Col md={12}>
              <div className="form__form-group">
                <label className="form__form-group-label">Sick Leave</label>
                <div className="form__form-group-field">
                  <FormField
                    name="sick"
                    control={control}
                    component="input"
                    type="number"
                    rules={{
                      required: "Sick leave is required",
                      max: {
                        value: 14, // Set maximum value
                        message: "Sick leave cannot exceed 14 days",
                      },
                    }}
                  />
                </div>
                {errors.sick && (
                  <span className="form__form-group-error">
                    {errors.sick.message}
                  </span>
                )}
              </div>
            </Col>
            <Col md={12}>
              <div className="form__form-group">
                <label className="form__form-group-label">Annual Leave</label>
                <div className="form__form-group-field">
                  <FormField
                    name="anual"
                    control={control}
                    component="input"
                    type="number"
                    rules={{
                      required: "Annual leave is required",
                      max: {
                        value: 365, // Set maximum value
                        message: "Annual leave cannot exceed 365 days",
                      },
                    }}
                  />
                </div>
                {errors.anual && (
                  <span className="form__form-group-error">
                    {errors.anual.message}
                  </span>
                )}
              </div>
            </Col>
            <Col md={12}>
              <div className="form__form-group">
                <label className="form__form-group-label">
                  Reimburse Leave
                </label>
                <div className="form__form-group-field">
                  <FormField
                    name="reimburse"
                    control={control}
                    component="input"
                    type="number"
                    rules={{
                      required: "Reimburse leave is required",
                      max: {
                        value: 365, // Set maximum value
                        message: "Reimburse leave cannot exceed 365 days",
                      },
                    }}
                  />
                </div>
                {errors.reimburse && (
                  <span className="form__form-group-error">
                    {errors.reimburse.message}
                  </span>
                )}
              </div>
            </Col>

            {userDetailsData?.getUserByUserid?.gender === "Female" ? (
              <>
                <Col md={12}>
                  <div className="form__form-group">
                    <label className="form__form-group-label">
                      Maternity Leave
                    </label>
                    <div className="form__form-group-field">
                      <FormField
                        name="maternity"
                        control={control}
                        component="input"
                        type="number"
                        rules={{ required: "Maternity leave is required" }}
                      />
                      {errors.maternity && (
                        <span className="form__form-group-error">
                          {errors.maternity.message}
                        </span>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="form__form-group">
                    <label className="form__form-group-label">
                      Menstrual Leave
                    </label>
                    <div className="form__form-group-field">
                      <FormField
                        name="menstrual"
                        control={control}
                        component="input"
                        type="number"
                        rules={{ required: "Menstrual leave is required" }}
                      />
                      {errors.menstrual && (
                        <span className="form__form-group-error">
                          {errors.menstrual.message}
                        </span>
                      )}
                    </div>
                  </div>
                </Col>
              </>
            ) : // <div>
            //   <span className="profile-rating">
            //     Maternity:{" "}
            //     {userDetailsData?.getUserByUserid?.additionalInfo?.maternity}
            //   </span>{" "}
            //   <br />
            //   <span className="profile-rating">
            //     Menstrual:{" "}
            //     {userDetailsData?.getUserByUserid?.additionalInfo?.menstrual}
            //   </span>{" "}
            // </div>
            null}

            {/* Buttons */}
            <Col xs={12}>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <ButtonToolbar className="form__button-toolbar">
                  <Button className="btn-sm" onClick={toggle} type="button">
                    Cancel
                  </Button>
                  <Button className="btn-sm ml-3" color="primary" type="submit">
                    {loading ? "Updating..." : "Update"}
                  </Button>
                </ButtonToolbar>
              </div>
            </Col>
          </form>
        </ModalBody>
      )}
    </Modal>
  );
};

export default LeaveUpdateForm;
