import React from "react";
import { Table } from "reactstrap";

const AttendanceReportTimeSlotSummary = ({ data }) => {
  let totalCount = 0;
  let totalPercentage = 0;

  data?.attendanceTimeSlot?.forEach((entry) => {
    totalCount += entry.count;
    totalPercentage += entry.percentage;
  });
  return (
    <div>
      <h4 className="mb-2">Attendance Summary</h4>
      <div>
        <p className="row">
          <div
            style={{
              color: "#999999",
              fontWeight: 500,
            }}
            className="col-auto p-0 pb-1"
          >
            Total Working Day:
          </div>
          <div className="col p-0 pl-2 pb-1">
            {data?.totalWorkingDays ?? 0} Day
            {data?.totalWorkingDays > 1 ? "s" : ""}
          </div>
        </p>
      </div>
      <Table role="table" striped hover responsive>
        <thead>
          <tr>
            <th>Time</th>
            <th className="text-center">Attendance</th>
            <th className="text-center">Percentage</th>
          </tr>
        </thead>
        <tbody>
          {data?.attendanceTimeSlot?.map((timeSlot, index) => {
            // console.log("time range count range:", timeSlot);
            return (
              <tr key={index + 1}>
                <td>{timeSlot?.range}</td>
                <td className="text-center">{timeSlot?.count}</td>
                <td className="text-center">{timeSlot?.percentage} %</td>
              </tr>
            );
          })}
          <tr className="border-top bg-light">
            <td className="text-center">
              <b>Total</b>
            </td>
            <td className="text-center">
              <b>
                {totalCount ?? 0} Day
                {totalCount > 1 ? "s" : ""}
              </b>
            </td>
            <td className="text-center">
              <b>{totalPercentage} %</b>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default AttendanceReportTimeSlotSummary;
