import { gql } from "@apollo/client";
import { GenderType } from "./fragments/GenderType";
import { LeaveTypesType } from "./fragments/LeaveTypesType";

export const CREATE_FARMER_MUTATION = gql`
  mutation createFamer($input: [FarmerInput!]!) {
    createFarmer(input: $input) {
      status
    }
  }
`;

export const UPDATE_FARMER = gql`
  mutation farmerUpdate(
    $id: ID
    $name: String
    $spouse_or_father: String
    $division: String
    $district: String
    $upazilla: String
    $village: String
    $mobile: String
    $age: Int
    $education_level: Int
    $total_members: Int
    $male_members: Int
    $female_members: Int
    $family_type: String
    $earning_members: Int
    $school_going: Int
    $farming_experience: Int
    $cultivable_area: String
    $images: String
  ) {
    updateFarmer(
      id: $id
      name: $name
      spouse_or_father: $spouse_or_father
      mobile: $mobile
      division: $division
      district: $district
      upazilla: $upazilla
      village: $village
      age: $age
      education_level: $education_level
      total_members: $total_members
      male_members: $male_members
      female_members: $female_members
      family_type: $family_type
      earning_members: $earning_members
      school_going: $school_going
      farming_experience: $farming_experience
      cultivable_area: $cultivable_area
      images: $images
    ) {
      name
    }
  }
`;
export const DELETE_FARMER = gql`
  mutation deleteFarmer($id: ID) {
    farmerDelete(id: $id) {
      status
    }
  }
`;
export const LOGIN_SITEADMIN = gql`
  mutation loginSiteAdmin($username: String, $password: String) {
    loginSiteAdmin(username: $username, password: $password) {
      name
      status
      result_code
      id
      username
      phone
      token
    }
  }
`;

export const GENERIC_INFO = gql`
  mutation genericInfo(
    $name: String
    $spouse_or_father: String
    $sex: String
    $division: Int
    $district: Int
    $upazilla: Int
    $union: Int
    $village: String
    $mobile: String
    $nid: String
    $age: String
    $education_level: String
    $total_members: Int
    $male_members: Int
    $female_members: Int
    $family_type: String
    $time_info: String
    $latitude: String
    $longitude: String
    $agent_name: String
    $school_going: Int
    $image: String
    $experience: Int
  ) {
    generic(
      name: $name
      spouse_or_father: $spouse_or_father
      sex: $sex
      division: $division
      district: $district
      upazilla: $upazilla
      union: $union
      village: $village
      mobile: $mobile
      nid: $nid
      age: $age
      education_level: $education_level
      total_members: $total_members
      male_members: $male_members
      female_members: $female_members
      family_type: $family_type
      time_info: $time_info
      latitude: $latitude
      longitude: $longitude
      agent_name: $agent_name
      school_going: $school_going
      image: $image
      experience: $experience
    ) {
      id
    }
  }
`;

export const UPDATE_GENERIC_INFO = gql`
  mutation updateGenericInfo(
    $id: ID
    $name: String
    $spouse_or_father: String
    $sex: String
    $division: Int
    $district: Int
    $upazilla: Int
    $union: Int
    $village: String
    $mobile: String
    $nid: String
    $age: String
    $education_level: String
    $total_members: Int
    $male_members: Int
    $female_members: Int
    $family_type: String
    $time_info: String
    $latitude: String
    $longitude: String
    $agent_name: String
    $school_going: Int
    $image: String
    $status: Boolean
    $experience: Int
  ) {
    updateGenericInfo(
      id: $id
      name: $name
      spouse_or_father: $spouse_or_father
      sex: $sex
      division: $division
      district: $district
      upazilla: $upazilla
      union: $union
      village: $village
      mobile: $mobile
      nid: $nid
      age: $age
      education_level: $education_level
      total_members: $total_members
      male_members: $male_members
      female_members: $female_members
      family_type: $family_type
      time_info: $time_info
      latitude: $latitude
      longitude: $longitude
      agent_name: $agent_name
      school_going: $school_going
      image: $image
      experience: $experience
      status: $status
    ) {
      id
    }
  }
`;

export const FINANCE_INFO = gql`
  mutation financeInfo(
    $gen_id: Int
    $land_area: String
    $occupation: [OccupationInput!]!
    $finance_support: [FinanceSupportInput!]!
    $own_leases: String
    $mouja: String
    $khotiyan: String
    $daag: String
  ) {
    financeInfo(
      gen_id: $gen_id
      land_area: $land_area
      finance_support: $finance_support
      occupation: $occupation
      own_leases: $own_leases
      mouja: $mouja
      khotiyan: $khotiyan
      daag: $daag
    ) {
      gen_id
      status
      land_area
      occupation {
        name
        incomes
      }
    }
  }
`;

export const FARMER_PROBLEMS = gql`
  mutation farmerProblems(
    $gen_id: Int
    $problems: [FarmerProblemsInput!]!
    $cold_storage: Int
    $comment_question: String
  ) {
    farmerProblems(
      gen_id: $gen_id
      problems: $problems
      cold_storage: $cold_storage
      comment_question: $comment_question
    ) {
      gen_id
      problems {
        name
      }
      cold_storage
      comment_question
      status
      result_code
    }
  }
`;

export const FARMING_PRACTICE = gql`
  mutation createFarmingPractice(
    $gen_id: Int
    $risk_of_flood: String
    $farming_year: String
    $cultivated_areas: String
    $training: String
    $seeds_collection: String
    $company: String
    $spray_time: String
    $weeds: String
    $production_cost: String
    $sell_product: String
    $selling_price: String
    $current_price: String
    $profit: String
    $disease_pests_dose: [DiseasePestDoseInput!]!
    $fertilizer: [FertilizerInput!]!
    $season: String
    $crop: String
    $variety: String
    $member_of_farmer_club: String
    $time_spending: String
    $price_unit: String
  ) {
    createFarmingPractices(
      gen_id: $gen_id
      risk_of_flood: $risk_of_flood
      farming_year: $farming_year
      cultivated_areas: $cultivated_areas
      training: $training
      seeds_collection: $seeds_collection
      company: $company
      spray_time: $spray_time
      weeds: $weeds
      production_cost: $production_cost
      sell_product: $sell_product
      selling_price: $selling_price
      current_price: $current_price
      profit: $profit
      disease_pests_dose: $disease_pests_dose
      fertilizer: $fertilizer
      season: $season
      crop: $crop
      variety: $variety
      member_of_farmer_club: $member_of_farmer_club
      time_spending: $time_spending
      price_unit: $price_unit
    ) {
      status
      gen_id
      season
      crop
      variety
    }
  }
`;

export const DIGITAL_LITERACY = gql`
  mutation farmerDigital(
    $gen_id: Int
    $type_of_phone: String
    $use_internet: String
    $pass_free_time: [FarmerFreeTimeInput!]!
    $member_of_farmer_club: String
    $time_spending: String
  ) {
    farmerDigitalStatus(
      gen_id: $gen_id
      type_of_phone: $type_of_phone
      use_internet: $use_internet
      pass_free_time: $pass_free_time
      member_of_farmer_club: $member_of_farmer_club
      time_spending: $time_spending
    ) {
      status
      pass_free_time {
        name
      }
    }
  }
`;

export const UPDATE_FARMING_PRACTICE = gql`
  mutation updateFarmingPractice(
    $id: ID
    $gen_id: Int
    $risk_of_flood: String
    $farming_year: String
    $cultivated_areas: String
    $training: String
    $seeds_collection: String
    $company: String
    $spray_time: String
    $weeds: String
    $production_cost: String
    $sell_product: String
    $selling_price: String
    $current_price: String
    $profit: String
    $disease_pests_dose: [DiseasePestDoseInput!]!
    $fertilizer: [FertilizerInput!]!
    $season: String
    $crop: String
    $variety: String
    $member_of_farmer_club: String
    $time_spending: String
    $price_unit: String
  ) {
    updateFarmingPractice(
      id: $id
      gen_id: $gen_id
      risk_of_flood: $risk_of_flood
      farming_year: $farming_year
      cultivated_areas: $cultivated_areas
      training: $training
      seeds_collection: $seeds_collection
      company: $company
      spray_time: $spray_time
      weeds: $weeds
      production_cost: $production_cost
      sell_product: $sell_product
      selling_price: $selling_price
      current_price: $current_price
      profit: $profit
      disease_pests_dose: $disease_pests_dose
      fertilizer: $fertilizer
      season: $season
      crop: $crop
      variety: $variety
      member_of_farmer_club: $member_of_farmer_club
      time_spending: $time_spending
      price_unit: $price_unit
    ) {
      status
      gen_id
      season
      crop
      variety
    }
  }
`;
export const DELETE_FINANCE = gql`
  mutation deleteFinance($gen_id: ID) {
    deleteFinance(gen_id: $gen_id) {
      status
    }
  }
`;

export const DELETE_FARMER_PROBLEMS = gql`
  mutation delete($gen_id: ID) {
    deleteFarmersProblem(gen_id: $gen_id) {
      status
    }
  }
`;

export const DELETE_FARMING_PRACTICE = gql`
  mutation delete($id: ID) {
    deleteFarmingPractice(id: $id) {
      status
    }
  }
`;
export const DELETE_FARMER_DIGITAL = gql`
  mutation delete($gen_id: ID) {
    deleteFarmerDigitalStatus(gen_id: $gen_id) {
      status
    }
  }
`;

export const CREATE_CROP_CATEGORY = gql`
  mutation createCropCategory($category_name: String, $category_code: String) {
    createCropCategories(
      category_name: $category_name
      category_code: $category_code
    ) {
      category_name
      result_code
    }
  }
`;
export const DELETE_CROP_CATEGORY = gql`
  mutation deleteCropCategory($id: Int, $category_name: String) {
    deleteCropCategory(id: $id, category_name: $category_name) {
      status
      result_code
    }
  }
`;
export const UPDATE_CATEGORY = gql`
  mutation updateCategory(
    $id: Int
    $category_name: String
    $category_code: String
  ) {
    updateCategory(
      id: $id
      category_name: $category_name
      category_code: $category_code
    ) {
      status
    }
  }
`;
export const CREATE_CROP = gql`
  mutation createCrop(
    $crop_name: String
    $scientific_name: String
    $crop_category: String
    $crop_family: String
    $general_info: String
    $image: String
  ) {
    createCrop(
      crop_name: $crop_name
      scientific_name: $scientific_name
      crop_family: $crop_family
      crop_category: $crop_category
      general_info: $general_info
      image: $image
    ) {
      status
      id
      crop_name
    }
  }
`;
export const UPDATE_CROP = gql`
  mutation updateCrop(
    $id: Int
    $crop_name: String
    $scientific_name: String
    $crop_category: String
    $crop_family: String
    $general_info: String
    $image: String
  ) {
    updateCrop(
      id: $id
      crop_name: $crop_name
      scientific_name: $scientific_name
      crop_family: $crop_family
      crop_category: $crop_category
      general_info: $general_info
      image: $image
    ) {
      status
      id
      crop_name
    }
  }
`;

export const CREATE_CLIMATE = gql`
  mutation createClimate(
    $crop_id: Int
    $climate_temperature_start: Int
    $climate_temperature_end: Int
    $climate_ph_start: Int
    $climate_ph_end: Int
    $climate_rainfall_start: Int
    $climate_rainfall_end: Int
    $climate_humidity: String
    $general_info: String
    $land_type: String
  ) {
    createClimate(
      crop_id: $crop_id
      climate_temperature_start: $climate_temperature_start
      climate_temperature_end: $climate_temperature_end
      climate_ph_start: $climate_ph_start
      climate_ph_end: $climate_ph_end
      climate_humidity: $climate_humidity
      general_info: $general_info
      land_type: $land_type
      climate_rainfall_start: $climate_rainfall_start
      climate_rainfall_end: $climate_rainfall_end
    ) {
      status
    }
  }
`;

export const CREATE_IRRIGATION = gql`
  mutation createIrrigation($crop_id: ID, $description: String) {
    createIrrigation(crop_id: $crop_id, description: $description) {
      status
    }
  }
`;
export const CREATE_FERTILIZER = gql`
  mutation createFertilizer($fertilizer: String, $crop_id: Int) {
    createFertilizer(crop_id: $crop_id, fertilizer: $fertilizer) {
      status
    }
  }
`;
export const CREATE_LAND_PREPARATION = gql`
  mutation createLandPreparation($description: String, $crop_id: Int) {
    createLandPreparation(crop_id: $crop_id, description: $description) {
      status
    }
  }
`;

export const CREATE_SEED = gql`
  mutation createSeed(
    $crop_id: Int
    $treatment: String
    $showing_method: String
    $seedbed: String
    $seed_rate: String
  ) {
    createSeed(
      crop_id: $crop_id
      treatment: $treatment
      showing_method: $showing_method

      seedbed: $seedbed
      seed_rate: $seed_rate
    ) {
      status
      crop_id
    }
  }
`;

export const CREATE_VARIETY = gql`
  mutation createVariety(
    $crop_id: Int
    $variety_name: String
    $variety_duration: String
    $season: String
    $variety_yield: String
    $special_character: String
    $time_showing: String
  ) {
    createVariety(
      crop_id: $crop_id
      variety_name: $variety_name
      variety_duration: $variety_duration
      season: $season
      variety_yield: $variety_yield
      special_character: $special_character
      time_showing: $time_showing
    ) {
      id
      status
    }
  }
`;
export const UPDATE_VARIETY = gql`
  mutation updateVariety(
    $id: Int
    $crop_id: Int
    $variety_name: String
    $variety_duration: String
    $season: String
    $variety_yield: String
    $special_character: String
    $time_showing: String
  ) {
    updateVariety(
      id: $id
      crop_id: $crop_id
      variety_name: $variety_name
      variety_duration: $variety_duration
      season: $season
      variety_yield: $variety_yield
      special_character: $special_character
      time_showing: $time_showing
    ) {
      status
    }
  }
`;
export const CREATE_SEASON = gql`
  mutation createSeason($variety_id: Int, $season: [SeasonInputType!]) {
    createSeason(variety_id: $variety_id, season: $season) {
      season
    }
  }
`;
export const CREATE_PESTICIDE = gql`
  mutation createPesticide(
    $crop_id: Int
    $disease_type: String
    $disease_name: String
    $damage_control: String
    $image: [ImageInputType!]!
    $control_measure: String
  ) {
    createPesticide(
      crop_id: $crop_id
      disease_type: $disease_type
      disease_name: $disease_name
      damage_control: $damage_control
      control_measure: $control_measure
      image: $image
    ) {
      status
      id
    }
  }
`;
export const CREATE_CHEMICAL_MANAGEMENT = gql`
  mutation createChemicalManagement(
    $pesticide_id: Int
    $chemical: [ChemicalManagementInputType!]
  ) {
    createChemicalManagement(pesticide_id: $pesticide_id, chemical: $chemical) {
      pesticide_id
      dose
      mixing
      price
      unit
      company_name
      group_name
      trade_name
    }
  }
`;
export const UPDATE_PESTICIDE = gql`
  mutation updatePesticide(
    $id: Int
    $crop_id: Int
    $disease_type: String
    $disease_name: String
    $damage_control: String
    $image: [ImageInputType!]!
    $control_measure: String
  ) {
    updatePesticide(
      id: $id
      crop_id: $crop_id
      disease_type: $disease_type
      disease_name: $disease_name
      damage_control: $damage_control
      control_measure: $control_measure
      image: $image
    ) {
      status
      id
    }
  }
`;

export const DELETE_CROP = gql`
  mutation deleteCrop($id: Int) {
    deleteCrop(id: $id) {
      status
    }
  }
`;
export const DELETE_LANDPREPARATION = gql`
  mutation deleteLandPreparation($crop_id: Int) {
    deleteLandPreparation(crop_id: $crop_id) {
      status
    }
  }
`;

export const DELETE_FERTILIZER = gql`
  mutation deleteFertilizer($crop_id: Int) {
    deleteFertilizer(crop_id: $crop_id) {
      status
    }
  }
`;
export const DELETE_IRRIGATION = gql`
  mutation deleteIrrigation($crop_id: Int) {
    deleteIrrigation(crop_id: $crop_id) {
      status
    }
  }
`;
export const DELETE_CLIMATE = gql`
  mutation deleteClimate($crop_id: Int) {
    deleteClimate(crop_id: $crop_id) {
      status
    }
  }
`;
export const DELETE_SEED = gql`
  mutation deleteSeed($crop_id: Int) {
    deleteSeed(crop_id: $crop_id) {
      status
    }
  }
`;
export const DELETE_PESTICIDE = gql`
  mutation deletePesticide($id: Int) {
    deletePesticide(id: $id) {
      status
    }
  }
`;
export const DELETE_VARIETY = gql`
  mutation deleteVariety($id: Int) {
    deleteVariety(id: $id) {
      status
    }
  }
`;

export const CREATE_HARVESTING = gql`
  mutation createHarvest($description: String, $crop_id: Int) {
    createHarvest(crop_id: $crop_id, description: $description) {
      status
    }
  }
`;

export const CREATE_OPERATION = gql`
  mutation createOperation($description: String, $crop_id: Int) {
    createOperation(crop_id: $crop_id, description: $description) {
      status
    }
  }
`;

export const DELETE_HARVESTING = gql`
  mutation deleteHarvest($crop_id: Int) {
    deleteHarvest(crop_id: $crop_id) {
      status
    }
  }
`;

export const DELETE_OPERATION = gql`
  mutation deleteOperation($crop_id: Int) {
    deleteOperation(crop_id: $crop_id) {
      status
    }
  }
`;

export const CREATE_TRAINER = gql`
  mutation createTraining(
    $training_title: String
    $trainer_name: String
    $training_description: String
    $date: String
  ) {
    createTraining(
      training_title: $training_title
      trainer_name: $trainer_name
      training_description: $training_description
      date: $date
    ) {
      status
    }
  }
`;
export const UPDATE_AGENT = gql`
  mutation updateAgent(
    $id: ID
    $name: String
    $email: String
    $phone: String
    $district: String
    $division: String
    $username: String
    $images: String
    $upazilla: String
  ) {
    updateAgent(
      id: $id
      name: $name
      username: $username
      email: $email
      phone: $phone
      district: $district
      division: $division
      images: $images
      upazilla: $upazilla
    ) {
      status
    }
  }
`;
export const CREATE_POST = gql`
  mutation createPost(
    $agent_id: Int
    $category: String
    $description: String
    $images: [PostImageType]
    $status: String
  ) {
    createPost(
      agent_id: $agent_id
      category: $category
      description: $description
      images: $images
      status: $status
    ) {
      status
      id
    }
  }
`;
export const UPDATE_POST = gql`
  mutation updatePost(
    $post_id: Int
    $agent_id: Int
    $category: String
    $description: String
    $images: [PostImageType]
    $status: String
  ) {
    updatePost(
      post_id: $post_id
      agent_id: $agent_id
      category: $category
      description: $description
      images: $images
      status: $status
    ) {
      status
      id
    }
  }
`;

export const DELETE_POST_BY_ID = gql`
  mutation deletePostById($id: Int) {
    deletePostById(id: $id) {
      id
      status
    }
  }
`;

export const CREATE_COMMENTS = gql`
  mutation createComment($agent_id: Int, $post_id: Int, $comments: String) {
    createComment(agent_id: $agent_id, post_id: $post_id, comments: $comments) {
      status
    }
  }
`;
export const CREATE_PARTICIPANTS = gql`
  mutation createParticipant(
    $training_id: Int
    $participant: [ParticipantInputType!]
  ) {
    createParticipant(training_id: $training_id, participant: $participant) {
      status
    }
  }
`;

export const DELETE_PARTICIPANT = gql`
  mutation deleteParticipant($id: Int) {
    deleteParticipant(id: $id) {
      status
    }
  }
`;

export const CREATE_UNIT = gql`
  mutation createUnit($unit_name: String, $unit_code: String) {
    createUnit(unit_name: $unit_name, unit_code: $unit_code) {
      unit_name
    }
  }
`;
export const UPDATE_UNIT = gql`
  mutation updateUnit($unit_name: String, $unit_code: String, $id: Int) {
    updateUnit(unit_name: $unit_name, unit_code: $unit_code, id: $id) {
      unit_name
    }
  }
`;
export const DELETE_UNIT = gql`
  mutation deleteUnit($id: Int) {
    deleteUnit(id: $id) {
      unit_name
    }
  }
`;

// create category mutation
export const CREATE_REQUEST_CATEGORY = gql`
  mutation createRequestCategory(
    $category_name: String
    $category_code: String
  ) {
    createRequestCategory(
      category_name: $category_name
      category_code: $category_code
    ) {
      status
    }
  }
`;

export const CREATE_HUB = gql`
  mutation createHub(
    $hub_name: String
    $hub_description: String
    $division: Int
    $district: Int
    $upazilla: Int
    $union: Int
    $village: String
    $banner_image: String
  ) {
    createHub(
      hub_name: $hub_name
      hub_description: $hub_description
      division: $division
      district: $district
      upazilla: $upazilla
      union: $union
      village: $village
      banner_image: $banner_image
    ) {
      status
    }
  }
`;

export const UPDATE_HUB = gql`
  mutation updateHub(
    $id: Int
    $hub_name: String
    $hub_description: String
    $division: Int
    $district: Int
    $upazilla: Int
    $union: Int
    $village: String
    $banner_image: String
  ) {
    updateHub(
      id: $id
      hub_name: $hub_name
      hub_description: $hub_description
      division: $division
      district: $district
      upazilla: $upazilla
      union: $union
      village: $village
      banner_image: $banner_image
    ) {
      status
    }
  }
`;

export const DELETE_HUB = gql`
  mutation hubDelete($id: Int) {
    hubDelete(id: $id) {
      status
    }
  }
`;

export const CREATE_HUB_PARTICIPATE = gql`
  mutation createHubParticipant(
    $hub_id: Int
    $participant: [HubParticipantInputType]
  ) {
    createHubParticipant(hub_id: $hub_id, participant: $participant) {
      status
    }
  }
`;

export const DELETE_HUB_PARTICIPANT = gql`
  mutation deleteHubParticipant($id: Int) {
    deleteHubParticipant(id: $id) {
      status
    }
  }
`;

export const CREATE_DIVISION = gql`
  mutation createDivision($name: String) {
    createDivision(name: $name) {
      name
    }
  }
`;
export const CREATE_DISTRICT = gql`
  mutation createDistrict(
    $name: String
    $division_id: Int
    $division_name: String
  ) {
    createDistrict(
      name: $name
      division_id: $division_id
      division_name: $division_name
    ) {
      name
    }
  }
`;
export const CREATE_UPAZILLA = gql`
  mutation createUpazilla(
    $name: String
    $district_id: Int
    $district_name: String
  ) {
    createUpazilla(
      name: $name
      district_id: $district_id
      district_name: $district_name
    ) {
      district_name
    }
  }
`;
export const CREATE_UNION = gql`
  mutation createUnion(
    $name: String
    $upazilla_id: Int
    $upazilla_name: String
  ) {
    createUnion(
      name: $name
      upazilla_id: $upazilla_id
      upazilla_name: $upazilla_name
    ) {
      upazilla_name
    }
  }
`;
export const DELETE_DIVISION = gql`
  mutation deleteDivision($id: Int) {
    deleteDivision(id: $id) {
      name
      status
    }
  }
`;
export const DELETE_DISTRICT = gql`
  mutation deleteDistrict($id: Int) {
    deleteDistrict(id: $id) {
      name
      status
    }
  }
`;

export const CREATE_PERMISSIONS = gql`
  mutation createPermission($permission_name: String) {
    createPermission(permission_name: $permission_name) {
      permission_name
    }
  }
`;
export const DELETE_PERMISSION_BY_ID = gql`
  mutation deletePermissionById($id: Int) {
    deletePermissionById(id: $id) {
      status
      result_code
    }
  }
`;
export const CREATE_ROLE = gql`
  mutation createRole($role_name: String) {
    createRole(role_name: $role_name) {
      status
    }
  }
`;
export const DELETE_UPAZILLA = gql`
  mutation deleteUpazilla($id: Int) {
    deleteUpazilla(id: $id) {
      name
      status
    }
  }
`;
export const DELETE_UNION = gql`
  mutation deleteUnion($id: Int) {
    deleteUnion(id: $id) {
      name
      status
    }
  }
`;
export const UPDATE_DIVISION = gql`
  mutation updateDivision($id: Int, $name: String) {
    updateDivision(id: $id, name: $name) {
      id
      name
    }
  }
`;
export const UPDATE_DISTRICT = gql`
  mutation updateDistrict(
    $id: Int
    $name: String
    $division_name: String
    $division_id: Int
  ) {
    updateDistrict(
      id: $id
      name: $name
      division_id: $division_id
      division_name: $division_name
    ) {
      id
      name
    }
  }
`;
export const UPDATE_UPAZILLA = gql`
  mutation updateUpazilla(
    $id: Int
    $name: String
    $district_name: String
    $district_id: Int
  ) {
    updateUpazilla(
      id: $id
      name: $name
      district_id: $district_id
      district_name: $district_name
    ) {
      id
      name
    }
  }
`;
export const UPDATE_UNION = gql`
  mutation updateUnion(
    $id: Int
    $name: String
    $upazilla_name: String
    $upazilla_id: Int
  ) {
    updateUnion(
      id: $id
      name: $name
      upazilla_id: $upazilla_id
      upazilla_name: $upazilla_name
    ) {
      id
      name
    }
  }
`;

export const UPDATE_ROLES = gql`
  mutation updateRoles(
    $id: Int
    $role_name: String
    $permissions: [RolePermissionType]
  ) {
    updateRoles(id: $id, permissions: $permissions, role_name: $role_name) {
      role_name
      permissions {
        permission_name
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser(
    $name: String
    $username: String
    $password: String
    $images: String
    $division: Int
    $district: Int
    $upazilla: Int
    $union: Int
    $village: String
    $phone: String
    $email: String
    $isActive: Boolean
    $role: [RoleInputType]
    $jobType: String
    $EmployeeClassification: String
    $workingTime: Int
    $qualification: String
    $documents: [String]
  ) {
    updateUser(
      name: $name
      username: $username
      password: $password
      images: $images
      division: $division
      district: $district
      upazilla: $upazilla
      union: $union
      email: $email
      village: $village
      role: $role
      phone: $phone
      isActive: $isActive
      jobType: $jobType
      documents: $documents
      EmployeeClassification: $EmployeeClassification
      workingTime: $workingTime
      qualification: $qualification
    ) {
      status
      result_code
    }
  }
`;

export const LOG_IN_USER = gql`
  mutation userLogin($username: String, $password: String) {
    userLogin(username: $username, password: $password) {
      status
      result_code
      role {
        role_name
        permissions {
          permission_name
        }
      }
      token
      additionalInfo {
        rfid
      }
    }
  }
`;

// user

export const CREATE_USER = gql`
  mutation createUser(
    $name: String
    $username: String
    $password: String
    $images: String
    $documents: [String]
    $division: Int
    $district: Int
    $upazilla: Int
    $union: Int
    $village: String
    $gender: String
    $phone: String
    $email: String
    $role: [RoleInputType]
    $isActive: Boolean
    $jobType: String
    $EmployeeClassification: String
    $workingTime: Int
    $qualification: String
  ) {
    createUser(
      name: $name
      username: $username
      password: $password
      images: $images
      division: $division
      district: $district
      upazilla: $upazilla
      union: $union
      gender: $gender
      email: $email
      village: $village
      role: $role
      phone: $phone
      isActive: $isActive
      jobType: $jobType
      documents: $documents
      EmployeeClassification: $EmployeeClassification
      workingTime: $workingTime
      qualification: $qualification
    ) {
      status
      result_code
      id
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: Int) {
    deleteUser(id: $id) {
      status
      result_code
    }
  }
`;

// additional info

export const CREATE_USER_ADDITIONAL_INFO = gql`
  mutation createUserAdditionalInfo(
    $user_id: Int
    $rfid: String
    $office_id: String
    $dept_id: Int
    $desig_id: Int
    $description: String
    $joining_date: String
  ) {
    createUserAdditionalInfo(
      user_id: $user_id
      rfid: $rfid
      office_id: $office_id
      dept_id: $dept_id
      desig_id: $desig_id
      description: $description
      joining_date: $joining_date
    ) {
      status
      description
      user_id
      office_id
      rfid
      result_code
    }
  }
`;

export const UPDATE_USER_ADDITIONAL_INFO = gql`
  mutation updateAdditionalInfo(
    $user_id: Int
    $rfid: String
    $office_id: String
    $dept_id: Int
    $desig_id: Int
    $description: String
    $joining_date: String
  ) {
    updateAdditionalInfo(
      user_id: $user_id
      rfid: $rfid
      office_id: $office_id
      dept_id: $dept_id
      desig_id: $desig_id
      description: $description
      joining_date: $joining_date
    ) {
      result_code
      status
    }
  }
`;

export const UPDATE_USER_LEAVE_ADDITIONAL_INFO = gql`
  mutation updateRestLeave(
    $user_id: Int
    $casual: Int
    $anual: Int
    $sick: Int
    $menstrual: Int
    $maternity: Int
    $reimburse: Int
  ) {
    updateRestLeave(
      user_id: $user_id
      casual: $casual
      anual: $anual
      sick: $sick
      menstrual: $menstrual
      maternity: $maternity
      reimburse: $reimburse
    ) {
      result_code
      status
    }
  }
`;

// department mutation
export const CREATE_DEPARTMENT = gql`
  mutation createDepartment(
    $name: String
    $description: String
    $user_id: Int
  ) {
    createDepartment(
      name: $name
      description: $description
      user_id: $user_id
    ) {
      status
      result_code
    }
  }
`;
export const UPDATE_DEPARTMENT = gql`
  mutation updateDepartment(
    $id: Int
    $name: String
    $description: String
    $user_id: Int
  ) {
    updateDepartment(
      id: $id
      name: $name
      description: $description
      user_id: $user_id
    ) {
      status
      result_code
    }
  }
`;
export const DELETE_DEPARTMENT_BY_ID = gql`
  mutation deleteDepartmentById($id: Int) {
    deleteDepartmentById(id: $id) {
      id
      name
      status
      result_code
    }
  }
`;

// designation mutation
export const CREATE_DESIGNATION = gql`
  mutation createDesignation(
    $name: String
    $description: String
    $dep_id: Int
  ) {
    createDesignation(name: $name, description: $description, dep_id: $dep_id) {
      status
      result_code
    }
  }
`;
export const UPDATE_DESIGNATION = gql`
  mutation updateDesignation(
    $id: Int
    $name: String
    $description: String
    $dep_id: Int
  ) {
    updateDesignation(
      id: $id
      name: $name
      description: $description
      dep_id: $dep_id
    ) {
      description
      dep_id
      name
      status
      result_code
    }
  }
`;
export const DELETE_DESIGNATION_BY_ID = gql`
  mutation deleteDesignation($id: Int) {
    deleteDesignation(id: $id) {
      id
      status
      result_code
    }
  }
`;

// leave

export const CREATE_LEAVE = gql`
  mutation createLeave(
    $user_id: Int
    $start_date: String
    $end_date: String
    $rfid: String
    $images: String
    $leave_reason: String
    $leave_label: String
    $leave_type: Int
    $to_email: String
    $subject_email: String
    $text_email: String
    $html_email: String
    $from_email: String
  ) {
    createLeave(
      user_id: $user_id
      start_date: $start_date
      end_date: $end_date
      to_email: $to_email
      rfid: $rfid
      images: $images
      subject_email: $subject_email
      text_email: $text_email
      from_email: $from_email
      html_email: $html_email
      leave_label: $leave_label
      leave_reason: $leave_reason
      leave_type: $leave_type
    ) {
      status
      result_code
    }
  }
`;

export const DELETE_LEAVE_BY_ID = gql`
  mutation deleteLeaveById($id: Int) {
    deleteLeaveById(id: $id) {
      status
      result_code
    }
  }
`;

export const LEAVE_APPROVE = gql`
  mutation leaveApprove(
    $user_id: Int
    $created_by: Int
    $leave_id: Int
    $penalty_leave_id: Int
    $leave_name: String
    $penaltyInfo: String
    $attendanceInfo: String
    $sendingEmailInfo: String
    $leave_type: String
    $rest_leave_number: Int
  ) {
    leaveApprove(
      user_id: $user_id
      created_by: $created_by
      leave_id: $leave_id
      penalty_leave_id: $penalty_leave_id
      leave_name: $leave_name
      penaltyInfo: $penaltyInfo
      sendingEmailInfo: $sendingEmailInfo
      leave_type: $leave_type
      attendanceInfo: $attendanceInfo
      rest_leave_number: $rest_leave_number
    ) {
      status
      result_code
    }
  }
`;
export const UPDATE_LEAVE = gql`
  mutation updateLeave(
    $id: Int
    $user_id: Int
    $created_by: Int
    $start_date: String
    $end_date: String
    $leave_status: String
    $leave_reason: String
    $rfid: String
    $leave_type: Int
    $leave_label: String
    $images: String
    $deny_reason: String
    $to_email: String
    $cc_email: String
    $subject_email: String
    $text_email: String
    $html_email: String
  ) {
    updateLeave(
      id: $id
      created_by: $created_by
      user_id: $user_id
      start_date: $start_date
      end_date: $end_date
      leave_status: $leave_status
      leave_reason: $leave_reason
      rfid: $rfid
      leave_type: $leave_type
      leave_label: $leave_label
      images: $images
      deny_reason: $deny_reason
      to_email: $to_email
      cc_email: $cc_email
      subject_email: $subject_email
      text_email: $text_email
      html_email: $html_email
    ) {
      status
      result_code
    }
  }
`;

// attendance
export const CREATE_ATTENDANCE = gql`
  mutation createAttendance($emp_id: String, $time: String, $user_ip: String) {
    createAttendance(emp_id: $emp_id, time: $time, user_ip: $user_ip) {
      status
      result_code
      mailSend
    }
  }
`;
export const UPDATE_ATTENDANCE = gql`
  mutation updateAttendance($attendanceId: Int!, $isAuthorized: Boolean!) {
    updateAttendance(attendanceId: $attendanceId, isAuthorized: $isAuthorized) {
      id
      user_id
      isAuthorized
      status
    }
  }
`;
export const CREATE_ATTENDANCE_BY_WEB = gql`
  mutation createAttendanceByWeb(
    $user_id: Int
    $time: String
    $user_ip: String
  ) {
    createAttendanceByWeb(user_id: $user_id, time: $time, user_ip: $user_ip) {
      status
      result_code
    }
  }
`;
export const CREATE_ATTENDANCE_BY_LEAVE = gql`
  mutation createAttendanceByLeave(
    $rfid: String
    $startDate: String
    $leave_id: Int
    $endDate: String
    $user_ip: String
    $type: Int
    $user_id: Int
    $created_user_id: Int
    $isWeekendAttendance: Boolean
  ) {
    createAttendanceByLeave(
      rfid: $rfid
      startDate: $startDate
      leave_id: $leave_id
      endDate: $endDate
      user_ip: $user_ip
      type: $type
      user_id: $user_id
      created_user_id: $created_user_id
      isWeekendAttendance: $isWeekendAttendance
    ) {
      status
      result_code
    }
  }
`;
export const CREATE_ATTENDANCE_MANUAL = gql`
  mutation createAttendanceManual(
    $user_id: Int
    $login_date: String
    $login_time: String
    $logout_time: String
    $user_ip: String
    $reason: String
    $created_user_id: Int
  ) {
    createAttendanceManual(
      user_id: $user_id
      login_date: $login_date
      login_time: $login_time
      logout_time: $logout_time
      user_ip: $user_ip
      reason: $reason
      created_user_id: $created_user_id
    ) {
      status
      result_code
    }
  }
`;
export const CREATE_ATTENDANCE_BY_ACCEPT_PENALTY = gql`
  mutation createAttendanceByAcceptPenalty(
    $user_id: Int
    $type: Int
    $user_ip: String
    $login_date: String
    $reason: String
    $created_user_id: Int
    $created_by: Int
    $penalty_id: Int
  ) {
    createAttendanceByAcceptPenalty(
      user_id: $user_id
      type: $type
      user_ip: $user_ip
      login_date: $login_date
      reason: $reason
      created_user_id: $created_user_id
      created_by: $created_by
      penalty_id: $penalty_id
    ) {
      status
      result_code
    }
  }
`;
export const LOGOUT_ATTENDANCE = gql`
  mutation logoutAttendance($user_id: Int, $time: String, $user_ip: String) {
    logoutAttendance(user_id: $user_id, time: $time, user_ip: $user_ip) {
      status
      result_code
    }
  }
`;

export const DELETE_ATTENDANCE_BY_CANCELED_LEAVE = gql`
  mutation deleteAttendanceByCanceledLeave(
    $rfid: String
    $startDate: String
    $endDate: String
  ) {
    deleteAttendanceByCanceledLeave(
      rfid: $rfid
      startDate: $startDate
      endDate: $endDate
    ) {
      status
      result_code
    }
  }
`;

// soft deletion
export const DEPARTMENT_DELETED_STATUS_UPDATE = gql`
  mutation departmentDeletedStatusUpdate($id: Int) {
    departmentDeletedStatusUpdate(id: $id) {
      status
      result_code
    }
  }
`;
export const LEAVE_DELETED_STATUS_UPDATE = gql`
  mutation leaveDeletedStatusUpdate($id: Int) {
    leaveDeletedStatusUpdate(id: $id) {
      status
      result_code
    }
  }
`;
export const PERMISSION_DELETED_STATUS_UPDATE = gql`
  mutation permissionDeletedStatusUpdate($id: Int) {
    permissionDeletedStatusUpdate(id: $id) {
      status
      result_code
    }
  }
`;
export const ROLE_DELETED_STATUS_UPDATE = gql`
  mutation roleDeletedStatusUpdate($id: Int) {
    roleDeletedStatusUpdate(id: $id) {
      status
      result_code
    }
  }
`;
export const USER_DELETED_STATUS_UPDATE = gql`
  mutation userDeletedStatusUpdate($id: Int) {
    userDeletedStatusUpdate(id: $id) {
      status
      result_code
    }
  }
`;
export const USER_ADDITIONAL_INFO_DELETED_STATUS_UPDATE = gql`
  mutation userAdditionalInfoDeletedStatusUpdate($id: Int) {
    userAdditionalInfoDeletedStatusUpdate(id: $id) {
      status
      result_code
    }
  }
`;
export const DESIGNATION_DELETED_STATUS_UPDATE = gql`
  mutation userDesignationDeletedStatusUpdate($id: Int) {
    userDesignationDeletedStatusUpdate(id: $id) {
      status
      result_code
    }
  }
`;

// customer registration/creation
export const CREATE_CUSTOMER = gql`
  mutation createCustomer(
    $fullname: String
    $password: String
    $address: String
    $phone: String
    $email: String
  ) {
    createCustomer(
      fullname: $fullname
      password: $password
      address: $address
      phone: $phone
      email: $email
    ) {
      status
      result_code
    }
  }
`;
export const CUSTOMER_LOGIN = gql`
  mutation customerLogin($password: String, $email: String) {
    customerLogin(password: $password, email: $email) {
      status
      result_code
      token
    }
  }
`;

// project proposal
export const CREATE_PROJECT_PROPOSAL = gql`
  mutation createProjectProposal(
    $customer_id: Int
    $title: String
    $description: String
    $images: String
    $start: String
    $end: String
    $service_type: String
    $project_type: String
    $client_type: Int
  ) {
    createProjectProposal(
      customer_id: $customer_id
      title: $title
      description: $description
      images: $images
      start: $start
      end: $end
      service_type: $service_type
      project_type: $project_type
      client_type: $client_type
    ) {
      status
      result_code
      id
    }
  }
`;
export const UPDATE_PROJECT_PROPOSAL = gql`
  mutation updateProjectProposal(
    $id: Int
    $customer_id: Int
    $title: String
    $description: String
    $images: String
    $start: String
    $end: String
    $service_type: String
    $project_type: String
    $client_type: Int
  ) {
    updateProjectProposal(
      id: $id
      customer_id: $customer_id
      title: $title
      description: $description
      images: $images
      start: $start
      end: $end
      service_type: $service_type
      project_type: $project_type
      client_type: $client_type
    ) {
      status
      result_code
      id
    }
  }
`;
export const SOFT_DELETE_PROJECT_PROPOSAL = gql`
  mutation softDeleteProjectProposal($id: Int) {
    softDeleteProjectProposal(id: $id) {
      status
      result_code
    }
  }
`;
export const ASSIGN_PROJECT_PROPOSAL = gql`
  mutation assignProjectProposal($dept_id: Int, $id: Int) {
    assignProjectProposal(dept_id: $dept_id, id: $id) {
      status
      result_code
    }
  }
`;
export const PROJECT_PROPOSAL_STATUS_UPDATE = gql`
  mutation projectProposalStatusUpdate($projectStatus: String, $id: Int) {
    projectProposalStatusUpdate(projectStatus: $projectStatus, id: $id) {
      status
      result_code
    }
  }
`;

// project proposal comment
export const CREATE_PROJECT_PROPOSAL_COMMENT = gql`
  mutation createProjectProposalComment(
    $customer_id: Int
    $project_proposal_id: Int
    $comment: String
    $attachment: String
  ) {
    createProjectProposalComment(
      customer_id: $customer_id
      project_proposal_id: $project_proposal_id
      comment: $comment
      attachment: $attachment
    ) {
      status
      result_code
      id
    }
  }
`;

// holydays
export const CREATE_HOLYDAY = gql`
  mutation createHolyday(
    $name: String
    $start_date: String
    $end_date: String
  ) {
    createHolyday(name: $name, start_date: $start_date, end_date: $end_date) {
      status
      result_code
    }
  }
`;
export const UPDATE_HOLYDAY = gql`
  mutation updateHolyday(
    $id: Int
    $name: String
    $start_date: String
    $end_date: String
  ) {
    updateHolyday(
      id: $id
      name: $name
      start_date: $start_date
      end_date: $end_date
    ) {
      status
      result_code
    }
  }
`;
export const DELETE_HOLYDAY = gql`
  mutation deleteHolyday($id: Int) {
    deleteHolyday(id: $id) {
      status
      result_code
    }
  }
`;

// penalty
export const CREATE_PENALTY = gql`
  mutation createPenalty(
    $user_id: Int
    $type: Int
    $reason: Int
    $date: String
  ) {
    createPenalty(
      user_id: $user_id
      type: $type
      reason: $reason
      date: $date
    ) {
      id
      status
      result_code
    }
  }
`;
export const UPDATE_PENALTY = gql`
  mutation updatePenalty($id: Int, $type: Int, $reason: Int, $date: String) {
    updatePenalty(id: $id, type: $type, reason: $reason, date: $date) {
      id
      status
      result_code
    }
  }
`;
export const DEDUCT_LEAVE_BY_PENALTY = gql`
  mutation deductLeaveByPenalty(
    $penalty_id: Int
    $created_by: Int
    $denied_reason: String
  ) {
    deductLeaveByPenalty(
      penalty_id: $penalty_id
      created_by: $created_by
      denied_reason: $denied_reason
    ) {
      status
      result_code
    }
  }
`;
export const DELETE_PENALTY = gql`
  mutation deletePenalty($id: Int) {
    deletePenalty(id: $id) {
      status
      result_code
    }
  }
`;

// penalty review
export const CREATE_PENALTY_REVIEW = gql`
  mutation createPenaltyReview(
    $penalty_id: Int
    $attendance_type: Int
    $attendance_reason: Int
  ) {
    createPenaltyReview(
      penalty_id: $penalty_id
      attendance_type: $attendance_type
      attendance_reason: $attendance_reason
    ) {
      status
      result_code
    }
  }
`;

// company
export const CREATE_COMPANY = gql`
  mutation createCompany($name: String, $description: String, $logo: String) {
    createCompany(name: $name, description: $description, logo: $logo) {
      id
      status
      result_code
    }
  }
`;
export const UPDATE_COMPANY = gql`
  mutation updateCompany(
    $id: Int
    $name: String
    $description: String
    $logo: String
  ) {
    updateCompany(
      id: $id
      name: $name
      description: $description
      logo: $logo
    ) {
      id
      status
      result_code
    }
  }
`;
export const COMPANY_SOFT_DELETE = gql`
  mutation companySoftDelete($id: Int) {
    companySoftDelete(id: $id) {
      status
      result_code
    }
  }
`;

// company
export const CREATE_COMPANY_COLLABORATION = gql`
  mutation createCompanyCollaboration(
    $company_id: Int
    $office_id: String
    $user_id: Int
  ) {
    createCompanyCollaboration(
      company_id: $company_id
      office_id: $office_id
      user_id: $user_id
    ) {
      id
      status
      result_code
    }
  }
`;
export const UPDATE_COMPANY_COLLABORATION = gql`
  mutation updateCompanyCollaboration(
    $id: Int
    $company_id: Int
    $office_id: String
    $user_id: Int
  ) {
    updateCompanyCollaboration(
      id: $id
      company_id: $company_id
      office_id: $office_id
      user_id: $user_id
    ) {
      id
      status
      result_code
    }
  }
`;
export const SOFT_DELETE_COMPANY_COLLABORATION = gql`
  mutation softDeleteCompanyCollaboration($id: Int) {
    softDeleteCompanyCollaboration(id: $id) {
      status
      result_code
    }
  }
`;

export const CREATE_LEAVE_TYPE = gql`
  ${LeaveTypesType}
  mutation createLeaveType($input: CreateLeaveTypesType) {
    createLeaveType(input: $input) {
      ...LeaveTypesType
    }
  }
`;
export const CREATE_GENDER = gql`
  ${GenderType}
  mutation createGender($input: CreateGenderType) {
    createGender(input: $input) {
      ...GenderType
    }
  }
`;
export const UPDATE_REWARD = gql`
  mutation updateReward($input: UpdateRewardType) {
    updateReward(input: $input) {
      message
      status_code
    }
  }
`;
export const CREATE_LEAVE_DEDUCTION = gql`
  mutation createLeaveDeduction($input: CreateLeaveDeductionType) {
    createLeaveDeduction(input: $input) {
      message
      status_code
    }
  }
`;
export const UPDATE_USER_PASSOWRD_BY_USERID = gql`
  mutation updateUserPassowrdByUserId($id: Int!, $password: String!) {
    updateUserPassowrdByUserId(id: $id, password: $password) {
      message
      status_code
    }
  }
`;

export const PASSWORD_RESET_BY_EMAIL = gql`
  mutation passwordResetByEmail($email: String!) {
    passwordResetByEmail(email: $email) {
      message
      status_code
    }
  }
`;

// notice

export const CREATE_NOTICE = gql`
  mutation createNotice(
    $name: String!
    $category: String!
    $description: String!
    $created_by: Int!
    $notice_status: String!
  ) {
    createNotice(
      name: $name
      category: $category
      description: $description
      created_by: $created_by
      notice_status: $notice_status
    ) {
      status
      result_code
    }
  }
`;
export const DELETE_NOTICE = gql`
  mutation deleteNotice($id: Int) {
    deleteNotice(id: $id) {
      status
      result_code
    }
  }
`;
export const UPDATE_NOTICE = gql`
  mutation updateNotice(
    $id: Int!
    $name: String
    $category: String
    $description: String
    $notice_status: String
  ) {
    updateNotice(
      id: $id
      name: $name
      category: $category
      description: $description

      notice_status: $notice_status
    ) {
      result_code
      status
      id
      name
      category
      description

      notice_status
    }
  }
`;
// export const UPDATE_USER_LEAVE_INFO = gql`
//   mutation updateUserLeaveInfo(
//     $userId: Int!
//     $casual: Int!
//     $sick: Int!
//     $anual: Int!
//     $reimburse: Int!
//   ) {
//     updateUserLeaveInfo(
//       userId: $userId
//       casual: $casual
//       sick: $sick
//       anual: $anual
//       reimburse: $reimburse
//     ) {
//       user_id
//       casual
//       sick
//       anual
//       reimburse
//     }
//   }
// `;
export const UPDATE_USER_LEAVE_INFO = gql`
  mutation updateUserLeaveInfo(
    $userId: Int!
    $casual: Int
    $sick: Int
    $anual: Int
    $reimburse: Int
    $menstrual: Int
    $maternity: Int
    $gender: String!
  ) {
    updateUserLeaveInfo(
      userId: $userId
      casual: $casual
      sick: $sick
      anual: $anual
      reimburse: $reimburse
      menstrual: $menstrual
      maternity: $maternity
      gender: $gender
    ) {
      userId
      casual
      sick
      anual
      reimburse
      menstrual
      maternity
    }
  }
`;
