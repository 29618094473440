import React, { useCallback, useEffect, useRef, useState } from "react";
import Scrollbar from "react-smooth-scrollbar";
import classNames from "classnames";
import ChatSearch from "./ChatSearch";
import ChatTopbar from "./ChatTopbar";
import ChatBubble from "./ChatBubble";
import ChatField from "./ChatField";
import ChatContact from "./ChatContact";
import ProposalContainer from "../../ProposalContainer";
import { useQuery } from "@apollo/client";
import { GET_PROJECT_PROPOSAL_COMMENTS_BY_PROPOSAL_ID } from "../../../../../GraphQL/Queries";
import { io } from "socket.io-client";
import ImageViewer from "react-simple-image-viewer";
import { socketApi } from "../../../../IotSwitch/SwitchBoard";

const Chat = ({
  currentUser,
  contacts,
  loggedInfo,
  currentChatFromList,
  openImageViewer,
  setImageArray,
  imageArray,
}) => {
  const [currentMessages, setCurrentMessages] = useState([]);
  const [currentChat, setCurrentChat] = useState(
    currentChatFromList ? currentChatFromList : contacts[0]
  );
  const [openContacts, setOpenContacts] = useState(false);
  const [commentSubmit, setCommentSubmit] = useState(0); // it is use for refetch after submit comments

  const [file, setFile] = useState([]);
  // socket io
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  const socket = useRef();

  // project proposal comments by proposal id
  const proposalComments = useQuery(
    GET_PROJECT_PROPOSAL_COMMENTS_BY_PROPOSAL_ID,
    {
      skip: !currentChat?.id,
      variables: {
        project_proposal_id: currentChat?.id,
      },
    }
  );
  useEffect(() => {
    if (true) {
      setCurrentMessages(
        proposalComments?.data?.projectProposalCommentsByProposalId
      );
    }
    proposalComments.refetch();
  }, [
    currentChat?.id,
    commentSubmit,
    proposalComments?.data?.projectProposalCommentsByProposalId,
  ]);

  const chatClass = classNames({
    chat: true,
    "chat--open": openContacts,
  });
  console.log(currentMessages);

  const contactsClass = classNames({
    "chat__contact-list": true,
    "chat__contact-list--open": openContacts,
  });

  const onOpenChat = (contact) => {
    const dialog = contacts?.find(
      (c) => c?.customerInfo?.email === contact
    )?.messages;
    const messages = dialog || null;
    setCurrentChat(contact);
    setCurrentMessages([]);
  };
  const onOpenContacts = () => {
    setOpenContacts(!openContacts);
  };

  // socket io implementation
  // connect to socket io backend
  useEffect(() => {
    socket.current = io(socketApi);
    socket.current.on("getMessage", (data) => {
      setArrivalMessage({
        attachment: data?.attachment,
        comment: data?.text,
        customer_id: data?.senderId,
        createdAt: Date.now(),
      });
    });
  }, []);

  // set live message from socket io
  useEffect(() => {
    arrivalMessage &&
      currentChat?.conversationUsers.includes(arrivalMessage?.customer_id) &&
      setCurrentMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage]);

  // send current login user to socket io
  useEffect(() => {
    socket.current.emit("addUser", loggedInfo?.id);
  }, [loggedInfo?.id]);

  // image viewer start
  const [imageArray2, setImageArray2] = useState([]);
  const [currentImage2, setCurrentImage2] = useState(0);
  const [isViewerOpen2, setIsViewerOpen2] = useState(false);
  const openImageViewer2 = useCallback((index) => {
    setCurrentImage2(index);
    setIsViewerOpen2(true);
  }, []);

  const closeImageViewer2 = () => {
    setCurrentImage2(0);
    setIsViewerOpen2(false);
  };

  return (
    <div
      className={chatClass}
      onClick={openContacts ? onOpenContacts : null}
      role="presentation"
    >
      <div className={contactsClass}>
        <ChatSearch />
        <div className="chat__contacts">
          <Scrollbar className="scroll chat__contacts-scroll" alwaysShowTracks>
            {contacts?.map((item) => (
              <button
                key={item?.id}
                type="button"
                onClick={(e) => onOpenChat(item, e)}
              >
                <ChatContact
                  active={item?.id === currentChat?.id}
                  contact={item}
                />
              </button>
            ))}
          </Scrollbar>
        </div>
      </div>

      {currentChat === null ? (
        <div className="chat__dialog">
          <ChatTopbar onClick={onOpenContacts} />
          <div className="chat__dialog-select-message">
            <p>Select a chat to start messaging</p>
          </div>
        </div>
      ) : (
        <div className="chat__dialog">
          <ChatTopbar
            contact={contacts?.find((item) => item?.id === currentChat?.id)}
            onClick={onOpenContacts}
          />
          <Scrollbar className="scroll chat__scroll" alwaysShowTracks>
            <div className="chat__dialog-messages-wrap">
              <div className="chat__dialog-messages">
                <ProposalContainer
                  data={currentChat}
                  setImageArray2={setImageArray2}
                  openImageViewer2={openImageViewer2}
                />
              </div>
            </div>
            <div className="chat__dialog-messages-wrap">
              {currentMessages === null || currentMessages?.length === 0 ? (
                <div className="chat__dialog-messages">
                  <div className="chat__dialog-messages-empty">
                    <p>No comments</p>
                  </div>
                  {/* image preview */}
                  <div className="upload-img-container d-flex flex-wrap">
                    {file?.map((item, index) => {
                      const extension =
                        item?.preview?.split(".")[
                          item?.url?.split(".")?.length - 1
                        ];
                      const thumb = ["pdf", "PDF", "doc", "docx", "ppt"];
                      setImageArray2((prevState) => [...prevState, item?.url]);
                      return thumb?.includes(extension) ? (
                        <div className="position-relative mt-4 mr-3">
                          <img
                            className="upload-img"
                            src="https://cutewallpaper.org/24/file-png/collections.png"
                            alt="image"
                          ></img>
                          <span
                            onClick={() => setFile([])}
                            className="upload-delete-btn position-absolute"
                          >
                            ❌
                          </span>
                        </div>
                      ) : (
                        <div className="position-relative mt-4 mr-3">
                          <img
                            className="upload-img"
                            src={item?.preview}
                            alt="image"
                          ></img>
                          <span
                            onClick={() => setFile([])}
                            className="upload-delete-btn position-absolute"
                          >
                            ❌
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div className="chat__dialog-messages">
                  <ChatBubble
                    contact={currentMessages}
                    message="nothing message"
                    date="no date"
                    active={true}
                    loggedInfo={loggedInfo}
                    openImageViewer={openImageViewer}
                    setImageArray={setImageArray}
                    imageArray={imageArray}
                  />

                  {/* image preview */}
                  <div className="upload-img-container d-flex flex-wrap">
                    {file?.map((item) => {
                      const extension =
                        item?.preview?.split(".")[
                          item?.url?.split(".")?.length - 1
                        ];
                      const thumb = ["pdf", "PDF", "doc", "docx", "ppt"];
                      return thumb?.includes(extension) ? (
                        <div className="position-relative mt-4 mr-3">
                          <img
                            className="upload-img"
                            src="https://cutewallpaper.org/24/file-png/collections.png"
                            alt="image"
                          ></img>
                          <span
                            onClick={() => setFile([])}
                            className="upload-delete-btn position-absolute"
                          >
                            ❌
                          </span>
                        </div>
                      ) : (
                        <div className="position-relative mt-4 mr-3">
                          <img
                            className="upload-img"
                            src={item?.preview}
                            alt="image"
                          ></img>
                          <span
                            onClick={() => setFile([])}
                            className="upload-delete-btn position-absolute"
                          >
                            ❌
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </Scrollbar>
          <ChatField
            socket={socket}
            currentChat={currentChat}
            setCommentSubmit={setCommentSubmit}
            file={file}
            setFile={setFile}
          />

          {isViewerOpen2 && (
            <ImageViewer
              src={imageArray2}
              currentIndex={currentImage2}
              onClose={closeImageViewer2}
              disableScroll={false}
              backgroundStyle={{
                backgroundColor: "rgba(0,0,0,0.9)",
              }}
              closeOnClickOutside={true}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Chat;
