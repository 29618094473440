/* eslint-disable comma-dangle */
/* eslint-disable react/prop-types */
import { useQuery } from "@apollo/client";
import jwt from "jsonwebtoken";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { GET_USER_BY_ID, GET_USER_BY_USERID } from "../../GraphQL/Queries";
import { FiEdit } from "react-icons/fi";
import PropagateLoader from "react-spinners/PropagateLoader";
import { useSelector } from "react-redux";
import ChangePassword from "./ChangePassword";
import { ageCalculator, dateTime } from "../../utils/helpers";

const AgentProfile = () => {
  const [user, setuser] = useState({});
  const history = useHistory();

  const [isChangePassword, setIsChangePassword] = useState(false);

  const loggedUser = history?.location;
  // dark mode css in
  const theme = useSelector((state) => state.theme.className);

  //
  useEffect(() => {
    const token = localStorage?.getItem("token");
    jwt.verify(token, "test", function (err, decoded) {
      if (err) {
        localStorage.removeItem("token");
      }
      setuser(decoded);
    });
  }, []);

  const LoadUserProfile = useQuery(GET_USER_BY_USERID, {
    skip: !user?.id,
    variables: {
      id: Number(user?.id),
    },
  });
  const LoadUpdateUserProfile = useQuery(GET_USER_BY_ID, {
    skip: !user?.id,
    variables: {
      id: Number(user?.id),
    },
  });
  let [color, setColor] = useState("rgb(54, 215, 183)");

  const { years, months, days } = ageCalculator(
    new Date(
      LoadUserProfile?.data?.getUserByUserid?.additionalInfo?.joining_date
    )
  );

  return (
    <Container>
      <div className="profile">
        <Row>
          <Col md={6} className="mx-auto">
            <Card>
              <CardBody>
                {LoadUserProfile?.loading ? (
                  <Row className="align-items-center justify-content-center">
                    <PropagateLoader
                      color={color}
                      loading={LoadUserProfile?.loading}
                      size={10}
                    />
                  </Row>
                ) : (
                  <Row>
                    <Col xs={12} className="mb-4">
                      <div className="card__title project-summary__card-title">
                        <div></div>
                        {/* <button onClick={() =>
                        history.push({
                          pathname: "/profile/update",
                          state: {
                            user: LoadUpdateUserProfile?.data?.getUserById,
                            id: user?.id,
                          },
                        })
                      } className="project-summary__btn btn btn-outline-secondary btn-sm"></button> */}
                        <FiEdit
                          className="profile-edit-btn"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            history.push({
                              pathname: "/profile/update",
                              state: {
                                user: LoadUpdateUserProfile?.data?.getUserById,
                                id: user?.id,
                              },
                            })
                          }
                        />
                      </div>

                      <div className="profile__card">
                        <div className="d-flex flex-column align-items-center">
                          <div className="profile__avatar">
                            {/* <img
                              src={
                                LoadUserProfile?.data?.getUserByUserid?.images
                                  ? LoadUserProfile?.data?.getUserByUserid
                                      ?.images
                                  : "https://electralink.com/wp-content/uploads/2015/12/leadership-profile.png"
                              }
                              alt="image"
                            /> */}
                            <img
                              className="img-fluid"
                              src={
                                LoadUserProfile?.data?.getUserByUserid
                                  ?.images ||
                                "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
                              }
                              alt="Profile"
                              onError={(e) => {
                                e.target.onerror = null; // Prevents looping if the fallback image also fails
                                e.target.src =
                                  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png";
                              }}
                            />
                          </div>
                          <div className="profile__data text-center mt-4">
                            <h4 className="profile__name">
                              {LoadUserProfile?.data?.getUserByUserid?.name}
                            </h4>
                            <p className="profile__work">
                              {
                                LoadUserProfile?.data?.getUserByUserid
                                  ?.additionalInfo?.designationInfo?.name
                              }
                              ,{" "}
                              {
                                LoadUserProfile?.data?.getUserByUserid
                                  ?.additionalInfo?.departmentInfo?.name
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12}>
                      <Row>
                        <Col xs={6}>
                          <Row>
                            <Col xs={12}>
                              <div className="panel__title mb-1">
                                <h5 className="bold-text">Contact</h5>
                              </div>
                              <table className="project-summary__info">
                                <tbody>
                                  <tr>
                                    <th>Email:</th>
                                    <td>
                                      {
                                        LoadUserProfile?.data?.getUserByUserid
                                          ?.email
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Phone:</th>
                                    <td>
                                      {
                                        LoadUserProfile?.data?.getUserByUserid
                                          ?.phone
                                      }
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </Col>
                            <Col xs={12}>
                              <div className="panel__title mb-1">
                                <h5 className="bold-text">Address</h5>
                              </div>
                              <table className="project-summary__info">
                                <tbody>
                                  <tr>
                                    <th>Village:</th>
                                    <td>
                                      {
                                        LoadUserProfile?.data?.getUserByUserid
                                          ?.village
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Union:</th>
                                    <td>
                                      {
                                        LoadUserProfile?.data?.getUserByUserid
                                          ?.unionInfo?.name
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Upazilla:</th>
                                    <td>
                                      {
                                        LoadUserProfile?.data?.getUserByUserid
                                          ?.upazillaInfo?.name
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>District:</th>
                                    <td>
                                      {
                                        LoadUserProfile?.data?.getUserByUserid
                                          ?.districtInfo?.name
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Division:</th>
                                    <td>
                                      {
                                        LoadUserProfile?.data?.getUserByUserid
                                          ?.divisionInfo?.name
                                      }
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={6}>
                          <Row>
                            <Col xs={12}>
                              <div className="panel__title mb-1">
                                <h5 className="bold-text">Office Info</h5>
                              </div>
                              <table className="project-summary__info ">
                                <tbody>
                                  <tr>
                                    <th>Login Id:</th>
                                    <td>
                                      {
                                        LoadUserProfile?.data?.getUserByUserid
                                          ?.username
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Office ID:</th>
                                    <td>
                                      {
                                        LoadUserProfile?.data?.getUserByUserid
                                          ?.additionalInfo?.office_id
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>RFID:</th>
                                    <td>
                                      {
                                        LoadUserProfile?.data?.getUserByUserid
                                          ?.additionalInfo?.rfid
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Joining Date:</th>
                                    <td>
                                      {dateTime(
                                        new Date(
                                          LoadUserProfile?.data?.getUserByUserid?.additionalInfo?.joining_date
                                        ).getTime(),
                                        "DD/MM/YYYY"
                                      )}{" "}
                                      ({`${years}Y ${months}M ${days}D`})
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </Col>
                            <Col xs={12}>
                              <div className="panel__title mb-1">
                                <h5 className="bold-text">Leave Left</h5>
                              </div>
                              <table className="project-summary__info">
                                {LoadUserProfile?.data?.getUserByUserid
                                  ?.gender == "Male" ? (
                                  <tbody>
                                    <tr>
                                      <th>Casual:</th>
                                      <td>
                                        {
                                          LoadUserProfile?.data?.getUserByUserid
                                            ?.additionalInfo?.casual
                                        }
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>Sick:</th>
                                      <td>
                                        {
                                          LoadUserProfile?.data?.getUserByUserid
                                            ?.additionalInfo?.sick
                                        }
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>Anual:</th>
                                      <td>
                                        {
                                          LoadUserProfile?.data?.getUserByUserid
                                            ?.additionalInfo?.anual
                                        }
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>Reimburse:</th>
                                      <td>
                                        {
                                          LoadUserProfile?.data?.getUserByUserid
                                            ?.additionalInfo?.reimburse
                                        }
                                      </td>
                                    </tr>
                                  </tbody>
                                ) : (
                                  <tbody>
                                    <tr>
                                      <th>Casual:</th>
                                      <td>
                                        {
                                          LoadUserProfile?.data?.getUserByUserid
                                            ?.additionalInfo?.casual
                                        }
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>Sick:</th>
                                      <td>
                                        {
                                          LoadUserProfile?.data?.getUserByUserid
                                            ?.additionalInfo?.sick
                                        }
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>Anual:</th>
                                      <td>
                                        {
                                          LoadUserProfile?.data?.getUserByUserid
                                            ?.additionalInfo?.anual
                                        }
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>Reimburse:</th>
                                      <td>
                                        {
                                          LoadUserProfile?.data?.getUserByUserid
                                            ?.additionalInfo?.reimburse
                                        }
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>Maternity:</th>
                                      <td>
                                        {
                                          LoadUserProfile?.data?.getUserByUserid
                                            ?.additionalInfo?.maternity
                                        }
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>Menstrual:</th>
                                      <td>
                                        {
                                          LoadUserProfile?.data?.getUserByUserid
                                            ?.additionalInfo?.menstrual
                                        }
                                      </td>
                                    </tr>
                                  </tbody>
                                )}
                              </table>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>

                    <Col xs={12} className="mt-3">
                      {!isChangePassword ? (
                        <div className="text-center">
                          <Button
                            onClick={() => setIsChangePassword(true)}
                            className="btn-sm"
                          >
                            Change Password
                          </Button>
                        </div>
                      ) : (
                        <ChangePassword
                          setIsChangePassword={setIsChangePassword}
                          isChangePassword={isChangePassword}
                          userId={LoadUserProfile?.data?.getUserByUserid?.id}
                        />
                      )}
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
          <Col md={8}></Col>
        </Row>
      </div>
    </Container>
  );
};

export default AgentProfile;
