import React, { useState } from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import axios from "axios";
import moment from "moment";
const DownloadAttendanceReport = ({ login_date, data }) => {
  const [isLoading, setIsLoading] = useState(false);

  if (data.length) {
    const transformData = (data) => {
      return data.map((item, index) => {
        const loginTime = item?.login_time ? moment(item?.login_time) : null;
        const formattedTime = loginTime ? loginTime.format("HH:mm:ss") : null;

        // Check if login time is after 9:30 AM
        const isAfter930 =
          formattedTime &&
          moment(formattedTime, "HH:mm:ss").isAfter(
            moment("09:30:00", "HH:mm:ss")
          );

        return {
          id: index + 1,
          name: item?.employeeInfo?.user?.name || "N/A",
          username: item?.employeeInfo?.user?.username || "N/A",
          rfid: item?.rfid || "N/A",
          login_date: item?.login_date || "N/A",
          login_time: item?.login_time
            ? `${moment(item?.login_time).format("hh:mm:ss A")}`
            : "N/A",
          logout_time: item?.logout_time
            ? `${moment(item?.logout_time).format("hh:mm:ss A")}`
            : "N/A",
          user_ip: item?.user_ip || "N/A",
          Authorization: isAfter930
            ? item?.isAuthorized
              ? "Authorized"
              : "Unauthorized"
            : "N/A",
        };
      });
    };

    const downloadReport = () => {
      setIsLoading(true);
      try {
        // Transform the data to include the desired columns
        const transformedData = transformData(data);

        const worksheet = XLSX.utils.json_to_sheet(transformedData); // Convert transformed data to worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Attendance Report");

        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });

        const fileData = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        saveAs(fileData, `Attendance_Report_${login_date}.xlsx`);
      } catch (error) {
        console.error("Error generating the Excel report:", error);
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <button
        className="btn btn-primary"
        onClick={downloadReport}
        disabled={isLoading || !data || data.length === 0}
      >
        {isLoading ? "Downloading..." : "Download Attendance"}
      </button>
    );
  } else {
    const downloadReport = async () => {
      setIsLoading(true);
      try {
        // Send a GET request to download the file
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_API_URL}/api/download-attendance?login_date=${login_date}`,
          {
            responseType: "blob", // Important for binary file downloads
          }
        );

        // Create a new Blob from the response data
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // MIME type for Excel
        });

        // Use file-saver to save the file
        saveAs(blob, `Attendance_Report_${login_date}.xlsx`);
      } catch (error) {
        console.error("Error downloading the report:", error);
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <button
        className="btn btn-primary"
        onClick={downloadReport}
        disabled={isLoading} // Disable button while loading
      >
        {isLoading ? "Downloading..." : "Download Attendance"}{" "}
        {/* Button text changes based on loading state */}
      </button>
    );
  }
};

export default DownloadAttendanceReport;
