import { Modal, ModalBody, Button, ButtonToolbar, Col, Row } from "reactstrap";
import { SelectField } from "@/shared/components/form/Select";
import { useForm } from "react-hook-form";
import FormField from "../../FormField";
import useLeaveApplicationDropdownsQuery from "../../../../../GraphQL/hooks/queries/useLeaveApplicationDropdownsQuery";
import { useEffect, useState } from "react";
import useUserDropdownsQuery from "../../../../../GraphQL/hooks/queries/useUserDropdownsQuery";
import useDepartmentDropdownsQuery from "../../../../../GraphQL/hooks/queries/useDepartmentDropdownsQuery";
import useCreateLeaveDeductionMutation from "../../../../../GraphQL/hooks/mutations/useCreateLeaveDeductionMutation";
import { toast } from "react-toastify";
import { loginInfo } from "../../../../../utils/helpers";
import { isEmptyObject } from "../../../../../utils/utilities";
import useAttendanceSummaryQuery from "../../../../../GraphQL/hooks/queries/useAttendanceSummaryQuery";
import { useHistory } from "react-router";
import Loader from "../../../../Account/Profile/components/Loader";
import {
  GET_LATE_LOGINS,
  GET_LEAVE_DEDUCTIONS_BY_USER,
} from "../../../../../GraphQL/Queries";
import { useQuery } from "@apollo/client";
import Swal from "sweetalert2";
const LateDeductionForm = ({ toggle, modal, leaveDeduction, userDetails }) => {
  // try code
  const history = useHistory();
  const [results, setResults] = useState({});

  const [searchData, setSearchData] = useState({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const isEmptyResults = isEmptyObject(results);
  const isEmptySearchData = isEmptyObject(searchData);
  const [isLoading, setIsLoading] = useState(true);
  const LoadAttendanceSummary = useAttendanceSummaryQuery({
    variables: {
      input: {},
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });
  const { loading, data: isLateData } = useQuery(GET_LEAVE_DEDUCTIONS_BY_USER, {
    variables: { user_id: userDetails?.id },
  });

  const {
    loading: lateLoginLoading,
    error,
    data: lateLoginsData,
  } = useQuery(GET_LATE_LOGINS, {
    variables: { user_id: userDetails?.id },
  });
  console.log("🚀 ~ LateDeductionForm ~ lateLoginsData:", lateLoginsData);

  const isLateFound = isLateData?.getLeaveDeductionByUser;
  console.log("🚀 ~ LateDeductionForm ~ isLateFound:", isLateFound);
  // Function to clear search data
  const onClear = () => {
    setSearchData({});
    setResults({});
  };

  const attendanceTimeSlot =
    results?.workingHour?.work_hour_report_history || [];

  // Function to convert time string to seconds
  function timeToSeconds(time) {
    const [hours, minutes, seconds] = time.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds; // Convert to total seconds
  }

  // Function to calculate late logins (9:30 AM or later)
  function calculateLateLogins(attendanceTimeSlot) {
    const lateLoginThreshold = "09:30:10"; // Late if login time is 9:30 AM or later
    const lateLoginThresholdInSeconds = timeToSeconds(lateLoginThreshold); // Convert threshold to seconds
    let lateCount = 0;

    attendanceTimeSlot.forEach((entry) => {
      const loginTime = entry?.login_time?.split(" ")[1]; // Get time portion from 'YYYY-MM-DD HH:MM:SS'

      if (loginTime) {
        const loginTimeInSeconds = timeToSeconds(loginTime); // Convert login time to seconds
        if (loginTimeInSeconds >= lateLoginThresholdInSeconds) {
          lateCount++;
        }
      }
    });

    return lateCount;
  }

  // Calculate late logins and penalties
  const lateDays = lateLoginsData?.getLateLogins?.totalLateLoginsCount;
  console.log("🚀 ~ LateDeductionForm ~ lateDays:", lateDays);
  console.log("🚀 ~ LateDeductionForm ~ lateDays:", lateDays);
  const lateLogins = calculateLateLogins(attendanceTimeSlot);
  console.log("🚀 ~ LateDeductionForm ~ lateLogins:", lateLogins);
  const penaltyDays = Math.floor(lateDays / 4); // Penalty days based on late logins
  console.log("🚀 ~ LateDeductionForm ~ penaltyDays:", penaltyDays);

  // Set the start and end dates automatically (first day of the month to today)

  useEffect(() => {
    const currentDate = new Date();

    // Calculate the first day of the previous month
    const firstDayOfPreviousMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      1
    );

    // Calculate the last day of the previous month
    const lastDayOfPreviousMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      0
    );

    // Format the date to 'YYYY-MM-DD'
    const formatDate = (date) => date.toISOString().split("T")[0];

    // Set the startDate and endDate
    setStartDate(formatDate(firstDayOfPreviousMonth));
    setEndDate(formatDate(lastDayOfPreviousMonth));
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const today = new Date();
    const formatDate = (date) => date.toISOString().split("T")[0];
    setStartDate(formatDate(firstDayOfMonth));
    setEndDate(formatDate(today));
  }, []);

  // Function to fetch attendance summary
  const fetchAttendanceSummary = async () => {
    if (!userDetails?.id) {
      console.error("User ID is not available");
      return;
    }

    const input = {
      start_date: new Date(startDate).getTime(),
      end_date: new Date(endDate).getTime(),
      user_id: userDetails?.id,
    };

    try {
      const searchedData = await LoadAttendanceSummary?.refetch({
        input,
      });

      setSearchData(input);
      setResults(searchedData?.data?.attendanceSummary); // Ensure the correct structure is used
    } catch (error) {
      console.error("Error fetching attendance summary:", error);
    }
  };

  // Automatically fetch attendance data when start or end date changes
  useEffect(() => {
    if (startDate && endDate) {
      fetchAttendanceSummary();
    }
  }, [startDate, endDate]);

  // Fetch on a button click (optional)
  const handleFetchClick = () => {
    fetchAttendanceSummary();
  };

  useEffect(() => {
    if (modal) {
      setIsLoading(true);
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 3000); // 2-second loading time

      // Clean up the timeout when the modal closes
      return () => clearTimeout(timer);
    }
  }, [modal]);
  // try code

  const loggedInfo = loginInfo();
  const [leaveDeductionForm, setLeaveDeductionForm] = useState({
    leaveTypes: [],
  });
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useForm();

  // ** leave deduction mutation
  const { createLeaveDeduction, loading: createLeaveDeductionLoading } =
    useCreateLeaveDeductionMutation();

  // ** leave type query
  const LoadApplicationDropdownData = useLeaveApplicationDropdownsQuery({
    variables: {
      input: {
        user_id: userDetails?.id,
      },
    },
    notifyOnNetworkStatusChange: true,
  });
  // ** employee dropdown query
  const LoadEmployeeDropdownData = useUserDropdownsQuery({
    variables: {
      input: {
        dept_id: userDetails?.additionalInfo?.dept_id,
      },
    },
    notifyOnNetworkStatusChange: true,
  });
  //   ** department dropdown query
  const LoadDepartmentDropdownData = useDepartmentDropdownsQuery({
    variables: {
      input: {},
    },
    notifyOnNetworkStatusChange: true,
  });

  // ** set leave types for dropdown
  useEffect(() => {
    if (LoadApplicationDropdownData?.data?.leaveTypes) {
      setLeaveDeductionForm((prevState) => ({
        ...prevState,
        leaveTypes: userDetails?.additionalInfo,
      }));
    }
  }, [LoadApplicationDropdownData?.data]);

  //   ** set employees for dropdown
  useEffect(() => {
    if (LoadEmployeeDropdownData?.data?.users) {
      setLeaveDeductionForm((prevState) => ({
        ...prevState,
        users: LoadEmployeeDropdownData?.data?.users,
      }));
    }
  }, [LoadEmployeeDropdownData?.data]);

  // ** set department for dropdown
  useEffect(() => {
    if (userDetails?.additionalInfo?.departmentInfo?.name) {
      setValue("department", userDetails?.additionalInfo?.departmentInfo?.name);
    }
  }, [setValue, userDetails]);

  // ** submit
  // const onSubmit = async (values) => {

  //   const deducted = await createLeaveDeduction({
  //     variables: {
  //       input: {
  //         employee_id: userDetails?.id,
  //         deductor_id: loggedInfo?.id,
  //         days: Number(values?.days),
  //         reason: values?.reason,
  //         leave_type_id: values?.leaveType?.value,
  //       },
  //     },
  //   });
  //   if (deducted?.data?.createLeaveDeduction?.status_code == 200) {
  //     LoadApplicationDropdownData?.refetch({
  //       input: {
  //         user_id: userDetails?.id,
  //       },
  //     });

  //     leaveDeduction?.leaveDeductions?.refetch({
  //       input: {
  //         page: leaveDeduction?.page,
  //         size: leaveDeduction?.size,
  //       },
  //     });
  //     toast.success(deducted?.data?.createLeaveDeduction?.message);
  //     history.push("/leave/deduction");
  //     toggle();
  //   }
  //   if (deducted?.data?.createLeaveDeduction?.status_code !== 200) {
  //     toast.error(deducted?.data?.createLeaveDeduction?.message);
  //   }
  // };

  const onSubmit = async (values) => {
    const done = isLateData?.getLeaveDeductionByUser.length !== 0;
    console.log("🚀 ~ onSubmit ~ done:", done, isLateData);
    if (isLateData?.getLeaveDeductionByUser.length !== 0) {
      // Show SweetAlert2 warning message
      Swal.fire({
        title: "Error",
        text: "Already Late Logging Leave Deducted",
        icon: "error", // 'error' for an error message
        confirmButtonText: "OK",
      });

      throw new Error("Already Late Loging Leave Deducted");
    }
    const deducted = await createLeaveDeduction({
      variables: {
        input: {
          employee_id: userDetails?.id,
          deductor_id: loggedInfo?.id,
          days: Number(values?.days),
          reason: values?.reason,
          leave_type_id: values?.leaveType?.value,
          isLate: true,
        },
      },
    });
    if (deducted?.data?.createLeaveDeduction?.status_code == 200) {
      LoadApplicationDropdownData?.refetch({
        input: {
          user_id: userDetails?.id,
        },
      });

      leaveDeduction?.leaveDeductions?.refetch({
        input: {
          page: leaveDeduction?.page,
          size: leaveDeduction?.size,
        },
      });
      toast.success(deducted?.data?.createLeaveDeduction?.message);
      history.push("/leave/deduction");
      toggle();
    }
    if (deducted?.data?.createLeaveDeduction?.status_code !== 200) {
      toast.error(deducted?.data?.createLeaveDeduction?.message);
    }
  };

  return (
    <Modal toggle={() => toggle()} isOpen={modal}>
      {isLoading || loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px", // Adjust the height based on your modal size
          }}
        >
          <div className="loading-spinner" />
          <Loader></Loader>
        </div>
      ) : (
        <ModalBody>
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <Col md={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">Department</span>
                <div className="form__form-group-field ">
                  <FormField
                    name="department"
                    errors={errors}
                    control={control}
                    component="input"
                    defaultValue={userDetails?.department}
                    readOnly={true}
                  />
                </div>
              </div>
            </Col>
            <Col md={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">Employee</span>
                <div className="form__form-group-field ">
                  <FormField
                    name="employee"
                    errors={errors}
                    control={control}
                    component="input"
                    defaultValue={userDetails?.name}
                    readOnly={true}
                  />
                </div>
              </div>
            </Col>
            <Col md={12}>
              <div className="form__form-group">
                <span className="form__form-group-label">Leave Type</span>
                <div className="form__form-group-field ">
                  <FormField
                    errors={errors}
                    name="leaveType"
                    control={control}
                    component={SelectField}
                    options={LoadApplicationDropdownData?.data?.leaveTypes}
                    placeholder="Select leave type"
                    onChange={(e) => {
                      setValue("leaveType", e);
                      if (e.leave_left == 0) {
                        setError("leaveType", {
                          type: "custom",
                          message: `${e.label?.split(" ")[0]} leave left only ${
                            e.leave_left
                          } day`,
                        });
                      } else {
                        clearErrors("leaveType");
                      }
                    }}
                    rules={{ required: "This is required field" }}
                  />
                </div>
              </div>
            </Col>
            <Col md={12}>
              <div className="form__form-group">
                <label className="form__form-group-label">
                  Number of deduction day
                </label>
                <div className="form__form-group-field">
                  <FormField
                    errors={errors}
                    name="days"
                    component="input"
                    control={control}
                    type="number"
                    step="0"
                    defaultValue={penaltyDays}
                    readOnly={true}
                  />
                </div>
              </div>
            </Col>
            <Col md={12}>
              <div className="form__form-group">
                <label className="form__form-group-label">Reason</label>
                <div className="form__form-group-field">
                  <FormField
                    errors={errors}
                    name="reason"
                    component="textarea"
                    control={control}
                    type="text"
                    placeholder={`Enter reason`}
                    defaultValue="Late Attendance"
                  />
                </div>
              </div>
            </Col>

            <Col xs={12}>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <ButtonToolbar className="form__button-toolbar">
                  <Button
                    className="btn-sm"
                    onClick={() => toggle()}
                    type="button"
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={
                      watch("leaveType")?.leave_left == 0 ? true : false
                    }
                    className="btn-sm ml-3"
                    color="primary"
                    type="submit"
                  >
                    {createLeaveDeductionLoading ? "Processing..." : "Deduct"}
                  </Button>
                </ButtonToolbar>
              </div>
            </Col>
          </form>
        </ModalBody>
      )}
    </Modal>
  );
};

export default LateDeductionForm;
