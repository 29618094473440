import { renderMaskedField } from "@/shared/components/form/FormField";
import PasswordField from "@/shared/components/form/Password";
import { SelectField } from "@/shared/components/form/Select";
import { SelectFieldDisabled } from "@/shared/components/form/DisabledSelect";
import DatePicker from "react-datepicker";
import images from "@/shared/img/uploader/placeholder.png";
import { useMutation, useQuery } from "@apollo/client";
import axios from "axios";
import { default as React, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import { ToastContainer } from "react-toastify";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Button, ButtonToolbar, Card, CardBody, Col, Row } from "reactstrap";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ImageIcon from "@mui/icons-material/Image";
import {
  CREATE_COMPANY_COLLABORATION,
  CREATE_USER,
  CREATE_USER_ADDITIONAL_INFO,
  UPDATE_COMPANY_COLLABORATION,
  UPDATE_USER,
  UPDATE_USER_ADDITIONAL_INFO,
} from "../../GraphQL/Mutation";
import {
  GET_ALL_DESIGNATION,
  GET_ALL_DEPARTMENT,
  EIXST_RFID,
  GET_ALL_COMPANIES,
  GET_USER_BY_USERID,
} from "../../GraphQL/Queries";
import { userAction } from "../../redux/actions/userAction";
import BASE_URL from "../../utils/api/baseUrl";
import { FormField } from "./FormField";
import { successMessage, warningMessage } from "../ReactToastify";
import jwt from "jsonwebtoken";
import { decryption } from "../HelperFunctions";
import BreadCrumb from "../../shared/components/BreadCrumb/BreadCrumb";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import TextEditor from "../../shared/components/textEditor/TextEditor";
import imageUrl from "@/utils/api/imageUrl";
import {
  educationOptions,
  EmployeeClassificationOptions,
  jobTypeOptions,
  workingTimeOptions,
} from "@/utils/dropdown";
import { use } from "react";

const CreateUser = ({ isHorizontal, isAboveError }) => {
  const history = useHistory();
  const updateUserInfo = history?.location?.state?.userInfo;

  const userId = history?.location?.state?.userId;
  const [companyCount, setCompanyCount] = useState(
    updateUserInfo
      ? [
          updateUserInfo?.companyCollaborationInfo?.map((item) => ({
            company: item?.company_id,
            office_id: item?.office_id,
          })),
        ]
      : [{ company: "", office_id: "", isCompany: false, isOffice_id: false }]
  );

  const [allCompanies, setAllCompanies] = useState([]);

  const {
    data: designationData,
    loading: designationLoading,
    error: designationError,
  } = useQuery(GET_ALL_DESIGNATION);

  const {
    data: departmentData,
    loading: departmentLoading,
    error: departmentError,
  } = useQuery(GET_ALL_DEPARTMENT);
  const LoadAllCompanies = useQuery(GET_ALL_COMPANIES, {
    onCompleted: (data) => {
      setAllCompanies(
        data?.getAllCompanies?.map((item) => ({
          label: item?.name,
          value: item?.id,
        }))
      );
    },
  });

  const token = localStorage?.getItem("token");
  const roles = decryption(JSON.parse(localStorage?.getItem("roles")));
  let userID;
  jwt.verify(token, "test", function (err, decoded) {
    if (err) {
      localStorage.removeItem("token");
    }
    userID = decoded?.id;
  });
  // use params use
  const location = useLocation();
  const [preview, setPreview] = useState("");
  const [createUser, createUserFeedback] = useMutation(CREATE_USER);
  const [createCompanyCollaboration, createCompanyCollaborationFeedback] =
    useMutation(CREATE_COMPANY_COLLABORATION);
  const [updateCompanyCollaboration, updateCompanyCollaborationFeedback] =
    useMutation(UPDATE_COMPANY_COLLABORATION);
  const [updateUser, updateUserFeedback] = useMutation(UPDATE_USER);
  const [updateUserAdditionalInfo, updateUserAdditionalInfoFeedback] =
    useMutation(UPDATE_USER_ADDITIONAL_INFO);
  const [createUserAdditionalInfo, createUserAdditionalInfoFeedback] =
    useMutation(CREATE_USER_ADDITIONAL_INFO);
  const dispatch = useDispatch();
  const [newFiles, setNewFiles] = useState([]);

  const [removeFiles, setRemoveFiles] = useState([]);

  const handleFileChange = (event) => {
    if (files.length > 2) {
      console.log("inside");
      return Swal.fire({
        title: "Upload up to three documents",
        text: "You can upload up to three documents.",
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancel",
        showConfirmButton: false, // Disable the confirm button
      });
    }
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    setNewFiles([...newFiles, ...selectedFiles]);
  };
  useEffect(() => {
    console.log(removeFiles); // Logs the state whenever it changes
  }, [removeFiles]);
  const handleRemoveFile = (fileName) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Remove file from `files` state
        setFiles((prevFiles) =>
          prevFiles.filter((file) =>
            typeof file === "string"
              ? file !== fileName
              : file.name !== fileName
          )
        );

        // Add file to `removeFiles` state
        setRemoveFiles((prevRemoveFiles) => [
          ...prevRemoveFiles,
          `documents/${fileName}`,
        ]);

        // Remove file from `isDocument` state
        setDocuments((prevDocuments) =>
          prevDocuments.filter((doc) =>
            typeof doc === "string" ? doc !== fileName : doc.name !== fileName
          )
        );

        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
      }
    });
  };

  // const handleRemoveFile = (fileName) => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       setFiles((prevFiles) =>
  //         prevFiles.filter((file) =>
  //           typeof file === "string"
  //             ? file !== fileName
  //             : file.name !== fileName
  //         )
  //       );
  //       setRemoveFiles((prevRemoveFiles) => {
  //         return [...prevRemoveFiles, fileName];
  //       });
  //       Swal.fire({
  //         title: "Deleted!",
  //         text: "Your file has been deleted.",
  //         icon: "success",
  //       });
  //     }
  //   });
  // };
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState("");
  const [message, setMessage] = useState("");
  const [designation, setDesignation] = useState("");
  const { data, loading: loadingData } = useQuery(GET_USER_BY_USERID, {
    skip: !userId,
    variables: {
      id: userId,
    },
    onCompleted: (data) => {
      setImage(data?.getUserByUserid?.images || "");
      setFiles(data?.getUserByUserid?.documents);
      setDesignation(
        data?.getUserByUserid?.additionalInfo?.designationInfo?.name
      );
    },
  });

  const [files, setFiles] = useState(data?.getUserByUserid?.documents || []);
  const [joiningDate, setJoiningDate] = useState(new Date()); // Default to today's date initially
  const [documentLoading, setDocumentLoading] = useState(false);
  useEffect(() => {
    if (data?.getUserByUserid?.additionalInfo?.joining_date) {
      const joiningDateStr = data.getUserByUserid.additionalInfo.joining_date;
      const parsedDate = Date.parse(joiningDateStr);

      if (!isNaN(parsedDate)) {
        setJoiningDate(new Date(parsedDate));
      }
    }
  }, [data]);

  const {
    handleSubmit,
    reset,
    watch,
    register,
    setError,
    clearErrors,
    setValue,
    trigger,
    control,
    formState: { errors },
  } = useForm();
  const existRfid = useQuery(EIXST_RFID);
  const onSubmit = async (data) => {
    setIsLoading(true);
    let isFile;
    let isDocument = documents ? [...documents] : []; // Default documents

    try {
      // Delete old image if exists
      if (data?.getUserByUserid?.images) {
        const deleteRes = await axios.post(
          `${BASE_URL}/api/image/image-delete`,
          {
            oldImage: data.getUserByUserid.images,
          }
        );

        if (deleteRes?.data) {
          const formData = new FormData();
          formData.append("file", image);
          formData.append("folderName", "users");
          const uploadRes = await axios.post(
            `${BASE_URL}/api/image/users/upload`,
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );
          isFile = uploadRes?.data?.data;
          setPreview("");
        }
      } else if (preview) {
        const formData = new FormData();
        formData.append("file", image);
        formData.append("folderName", "users");
        const uploadRes = await axios.post(
          `${BASE_URL}/api/image/users/upload`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        const img = uploadRes?.data?.data;
        isFile = `${imageUrl}/users/${img}`;
        // isFile = uploadRes?.data?.data;
        // setPreview("");
      }

      // Document upload
      if (removeFiles.length) {
        const response = await axios.post(
          `${BASE_URL}/api/image/filesDeleted`,
          {
            filesToDelete: removeFiles,
          }
        );
        if (response.status === 200) {
          console.log("Images deleted successfully:", response.data);
        }
      }
      if (newFiles.length) {
        const formDataDocument = new FormData();
        formDataDocument.append("folderName", "documents");
        files.forEach((file) => formDataDocument.append("files", file));

        const docRes = await axios.post(
          `${BASE_URL}/api/image/document-upload`,
          formDataDocument,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        if (docRes?.data?.data) {
          isDocument = [...isDocument, ...docRes.data.data];
        }
      }

      // Validate RFID existence
      const resultexistRfid = await existRfid.refetch({ rfid: data?.rfid });
      if (resultexistRfid?.data?.existRFID?.rfid && !updateUserInfo?.id) {
        warningMessage("RFID already taken", 3000);
        throw new Error("RFID already exists");
      }

      // Prepare data for user creation/update
      const createData = {
        district: Number(data?.district?.value),
        division: Number(data?.division?.value),
        upazilla: Number(data?.upazilla?.value),
        union: Number(data?.union.value),
        village: data?.village,
        name: data?.name,
        username: data?.username.trim().toLowerCase(),
        phone: data?.mobile,
        password: data?.password || updateUserInfo?.password,
        images: isFile ?? image,
        documents: isDocument || null,
        email: data?.email,
        gender: data?.gender?.value,
        isActive: data?.isActive.value,
        jobType: data?.jobType,
        EmployeeClassification: data?.EmployeeClassification,
        workingTime: data?.workingTime,
        qualification: data?.qualification,
      };
      console.log(
        "🚀 ~ onSubmit ~ createData.documents:",
        createData.documents
      );

      const additionalData = {
        rfid: data?.rfid,
        office_id: data?.office_id,
        dept_id: Number(data?.dept_id?.value),
        desig_id: Number(data?.desig_id?.value),
        description: data?.description,
        joining_date: String(joiningDate),
      };
      const companyData = {
        company_id: data?.company?.value,
        office_id: data?.office_id,
      };
      // Update user if ID exists
      if (updateUserInfo?.id) {
        const result = await updateUser({
          variables: { ...createData, id: Number(updateUserInfo.id) },
        });

        const result2 = await updateUserAdditionalInfo({
          variables: { ...additionalData, user_id: Number(updateUserInfo.id) },
        });

        const result3 = await updateCompanyCollaboration({
          variables: {
            ...companyData,
            user_id: Number(updateUserInfo.id),
            id: Number(data?.getUserByUserid?.companyCollaborationInfo[0]?.id),
          },
        });

        if (
          result?.data?.updateUser?.result_code === "000" &&
          result2?.data?.updateAdditionalInfo?.result_code === "000"
        ) {
          successMessage("Updated successfully!", 3000);
          history.goBack();
        } else {
          warningMessage("Update failed. Please check input values.", 3000);
        }
      } else {
        // Create new user
        const createResult = await createUser({ variables: createData });

        if (!createResult?.data?.createUser?.id) {
          Swal.fire({
            html: `<span style="color: red;">${createResult?.data?.createUser?.status}</span>`,
            icon: "error",
          });
          throw new Error("User creation failed");
        }

        // Create additional info
        const result2 = await createUserAdditionalInfo({
          variables: {
            ...additionalData,
            user_id: Number(createResult.data.createUser.id),
          },
        });
        // create company info
        const result3 = await createCompanyCollaboration({
          variables: {
            ...companyData,
            user_id: Number(createResult.data.createUser.id),
          },
        });

        if (result2?.data?.createUserAdditionalInfo?.result_code === "000") {
          Swal.fire(
            "Created!",
            "User has been successfully created.",
            "success"
          );
          history.push(`/user/list`);
        }
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage(
        error.response?.data?.message || error.message || "An error occurred"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setPreview(URL.createObjectURL(file));
    }
  };

  const getDivision = JSON.parse(localStorage?.getItem("division"));
  const getDistrict = JSON.parse(localStorage?.getItem("district"))?.filter(
    (data) => data?.division_id === watch("division")?.value
  );
  const getUpazilla = JSON.parse(localStorage?.getItem("upazilla"))?.filter(
    (data) => data?.district_id == watch("district")?.value
  );
  // const getUnion = JSON.parse(localStorage?.getItem("upazilla"))?.filter(
  //   (data) => data?.district_id == watch("district")?.value
  // );
  const getUnion = JSON.parse(localStorage?.getItem("union"))?.filter(
    (data) => data?.upazilla_id === watch("upazilla")?.value
  );

  const breadCrumbData = [
    {
      name: "Dashboard",
      link: "/",
    },
    {
      name: "List",
      link: "/user/list",
    },
    {
      name: updateUserInfo?.id ? "Update User" : "Create User",
    },
  ];
  const handleDateChange = (date) => {
    const today = new Date();

    if (!date || isNaN(date.getTime())) {
      alert("Invalid date value. Please select a valid date.");
      setJoiningDate(null);
    } else if (date > today) {
      alert("Future dates are not allowed.");
      setJoiningDate(null);
    } else {
      setJoiningDate(date);
    }
  };

  const getAvailableCompanies = (index) => {
    if (!allCompanies || !companyCount) return []; // Return an empty array if data is missing

    return allCompanies.filter(
      (company) =>
        !companyCount.some(
          (item, idx) =>
            item?.company && item.company === company.value && idx !== index
        )
    );
  };
  // try code
  const [selectedDeptId, setSelectedDeptId] = useState(null);

  // Handle department selection change
  const handleDeptChange = (selectedOption) => {
    setSelectedDeptId(selectedOption?.value || null);
    // setSelectDepartment(selectedOption?.label || null);
    // Update state with selected department ID
  };

  const departmentId = data?.getUserByUserid?.additionalInfo?.dept_id;
  const designationtId = data?.getUserByUserid?.additionalInfo?.desig_id;

  // Find the default department option for update mode
  const defaultDepartment = departmentData?.getAllDepartments?.find(
    (department) => department.id === departmentId
  );
  const defaultDesignation = designationData?.getAllDesignation?.find(
    (designation) => designation.id === designationtId
  );

  useEffect(() => {
    // Set department field value only if updating and departmentId is available
    if (departmentId && defaultDepartment) {
      setValue("dept_id", {
        label: defaultDepartment.name,
        value: defaultDepartment.id,
      });
    }
  }, [departmentId, defaultDepartment]);

  useEffect(() => {
    // Set department field value only if updating and departmentId is available
    if (designationtId && defaultDesignation) {
      setValue("desig_id", {
        label: defaultDesignation.name,
        value: defaultDesignation.id,
      });
    }
  }, [designationtId, defaultDesignation]);

  const setAndClearFields = (setValue, mainField, mainValue, fieldsToClear) => {
    setValue(mainField, mainValue);

    fieldsToClear.forEach((field) => setValue(field, ""));
  };

  // Filter designations based on the selected department ID
  const desigId = data?.getUserByUserid?.additionalInfo?.desig_id;

  // const filteredDesignations = desigId
  //   ? designationData?.getAllDesignation?.filter(
  //       (designation) => designation?.id === desigId
  //     )
  //   : [];
  const filteredDesignations = selectedDeptId
    ? designationData?.getAllDesignation?.filter(
        (designation) => designation?.dep_id === selectedDeptId
      )
    : [];

  const [documents, setDocuments] = useState(
    data?.getUserByUserid?.documents || []
  );
  // const documents = data?.getUserByUserid?.documents || [];

  const renderDocument = (fileName) => {
    const fileExtension = fileName.split(".").pop().toLowerCase();

    if (["jpg", "jpeg", "png"].includes(fileExtension)) {
      // For image files, display the image directly
      return (
        <img
          src={`https://storage.googleapis.com/aunkur/office-mngt/users/${fileName}`}
          alt={fileName}
          style={{
            width: "150px",
            height: "150px",
            objectFit: "cover",
            marginRight: "10px",
          }}
        />
      );
    } else if (fileExtension === "pdf") {
      // For PDF files, embed the PDF directly
      return (
        <embed
          src={`https://storage.googleapis.com/aunkur/office-mngt/users/${fileName}`}
          type="application/pdf"
          width="150px"
          height="150px"
          style={{ marginRight: "10px" }}
        />
      );
    } else {
      // Unsupported file type
      return <span>Unsupported file format</span>;
    }
  };

  const handleRemoveDocument = (fileNameToRemove) => {
    setDocuments((prevDocuments) =>
      prevDocuments.filter((fileName) => fileName !== fileNameToRemove)
    );
  };

  // Loading and error handling
  if (departmentLoading || designationLoading || loadingData)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh", // Optional, for full vertical centering
          textAlign: "center",
          padding: "20px",
        }}
      >
        <span
          className="spinner-border spinner-border-lg" // text-success adds green color
          role="status"
          aria-hidden="true" // Ensures green color if text-success isn't sufficient
        ></span>
        <p style={{ color: "black" }}>Loading...</p>
      </div>
    );
  if (departmentError) return <p>Error loading departments</p>;
  if (designationError) return <p>Error loading designations</p>;

  return (
    <div>
      {
        <Col md={12} lg={12} xl={12}>
          <Row>
            <Col>
              <BreadCrumb breadCrumbData={breadCrumbData} />
            </Col>
          </Row>
          <Card>
            {loadingData ? (
              <div style={{ textAlign: "center", padding: "20px" }}>
                <span
                  className="spinner-border spinner-border-lg"
                  role="status"
                  aria-hidden="true"
                ></span>
                <p>Loading...</p>
              </div>
            ) : (
              <CardBody>
                <h3 className="page-title">
                  {updateUserInfo?.id ? "Update user" : "Create new user"}
                </h3>
                <form
                  className={`form ${isHorizontal && "form--horizontal"}`}
                  style={{ width: "100%" }}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Col md={8} className="mx-auto">
                    <Row>
                      <Col md={12}>
                        <div className="form__form-group">
                          <div className="input__container">
                            {preview ? (
                              <img
                                src={preview}
                                className="image-box"
                                style={{ width: "100px" }}
                                alt=""
                              />
                            ) : image ? (
                              <img
                                src={image}
                                style={{ width: "100px" }}
                                className="image-box"
                                alt=""
                              />
                            ) : (
                              <img
                                src={images}
                                style={{ width: "100px" }}
                                className="image-box"
                                alt=""
                              />
                            )}
                            <input
                              name="images"
                              accept=" .jpg, .jpeg, .png"
                              id="images"
                              className="input--box"
                              type="file"
                              {...register("images")}
                              onChange={handleChange}
                            />
                          </div>
                          <div
                            style={{
                              width: "200px",
                              margin: "10px  auto 0 auto",
                            }}
                          >
                            {errors?.images && (
                              <span style={{ color: "red" }}>
                                image is required
                              </span>
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Full Name
                          </span>
                          <div className="form__form-group-field">
                            <FormField
                              name="name"
                              control={control}
                              component="input"
                              errors={errors}
                              rules={{ required: "This is required field" }}
                              isAboveError={isAboveError}
                              placeholder="Name"
                              defaultValue={data?.getUserByUserid?.name || ""}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Username
                          </span>
                          <div className="form__form-group-field">
                            <FormField
                              name="username"
                              control={control}
                              component="input"
                              errors={errors}
                              defaultValue={
                                data?.getUserByUserid?.username || ""
                              }
                              rules={{
                                required: "This is a required field",
                              }}
                              isAboveError={isAboveError}
                              placeholder="username"
                              disabled={updateUserInfo ? true : false}
                              onChange={(e) => {
                                const value = e.target.value;
                                setValue("username", value); // Update the value in react-hook-form
                                trigger("username"); // Trigger validation for the username field
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">Gender</span>
                          <div className="form__form-group-field">
                            <FormField
                              name="gender"
                              isDisabled={updateUserInfo ? true : false}
                              control={control}
                              component={SelectField}
                              errors={errors}
                              options={[
                                {
                                  value: "Male",
                                  label: "Male",
                                },
                                {
                                  value: "Female",
                                  label: "Female",
                                },
                              ]}
                              rules={{ required: "This is required field" }}
                              defaultValue={[
                                {
                                  value: "Male",
                                  label: "Male",
                                },
                                {
                                  value: "Female",
                                  label: "Female",
                                },
                              ].find(
                                (item) =>
                                  item?.value == data?.getUserByUserid?.gender
                              )}
                              // value="dhjs"
                              isAboveError={isAboveError}
                              placeholder="Select Gender"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Division
                          </span>
                          <div className="form__form-group-field">
                            <FormField
                              name="division"
                              control={control}
                              component={SelectField}
                              errors={errors}
                              defaultValue={
                                data?.getUserByUserid?.divisionInfo?.id
                                  ? {
                                      value:
                                        data?.getUserByUserid?.divisionInfo?.id,
                                      label:
                                        data?.getUserByUserid?.divisionInfo
                                          ?.name,
                                    }
                                  : ""
                              }
                              options={getDivision?.map((data) => ({
                                value: data?.id,
                                label: data?.name,
                              }))}
                              onChange={(e) => {
                                setAndClearFields(setValue, "division", e, [
                                  "district",
                                  "upazilla",
                                  "union",
                                ]);
                                // console.log(e);
                                // setValue("division", e);
                                // setValue("district", "");
                                // setValue("upazilla", "");
                                // setValue("union", "");
                              }}
                              rules={{ required: "This is required field" }}
                              isAboveError={isAboveError}
                              placeholder="Division"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            District
                          </span>
                          <div className="form__form-group-field">
                            <FormField
                              name="district"
                              control={control}
                              component={SelectField}
                              errors={errors}
                              defaultValue={
                                data?.getUserByUserid?.districtInfo?.id
                                  ? {
                                      value:
                                        data?.getUserByUserid?.districtInfo?.id,
                                      label:
                                        data?.getUserByUserid?.districtInfo
                                          ?.name,
                                    }
                                  : ""
                              }
                              options={getDistrict?.map((data) => ({
                                value: data?.id,
                                label: data?.name,
                              }))}
                              onChange={(e) => {
                                setAndClearFields(setValue, "district", e, [
                                  "upazilla",
                                  "union",
                                ]);
                              }}
                              rules={{ required: "This is required field" }}
                              isAboveError={isAboveError}
                              placeholder="District"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Upazilla
                          </span>
                          <div className="form__form-group-field">
                            <FormField
                              name="upazilla"
                              control={control}
                              component={SelectField}
                              errors={errors}
                              defaultValue={
                                data?.getUserByUserid?.upazillaInfo?.id
                                  ? {
                                      value:
                                        data?.getUserByUserid?.upazillaInfo?.id,
                                      label:
                                        data?.getUserByUserid?.upazillaInfo
                                          ?.name,
                                    }
                                  : ""
                              }
                              options={getUpazilla?.map((data) => ({
                                value: data?.id,
                                label: data?.name,
                              }))}
                              onChange={(e) => {
                                setAndClearFields(setValue, "upazilla", e, [
                                  "union",
                                ]);
                              }}
                              rules={{ required: "This is required field" }}
                              isAboveError={isAboveError}
                              placeholder="Upazilla"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">Union</span>
                          <div className="form__form-group-field">
                            <FormField
                              name="union"
                              control={control}
                              component={SelectField}
                              errors={errors}
                              defaultValue={
                                data?.getUserByUserid?.unionInfo?.id
                                  ? {
                                      value:
                                        data?.getUserByUserid?.unionInfo?.id,
                                      label:
                                        data?.getUserByUserid?.unionInfo?.name,
                                    }
                                  : ""
                              }
                              options={getUnion?.map((data) => ({
                                value: data?.id,
                                label: data?.name,
                              }))}
                              rules={{ required: "This is required field" }}
                              isAboveError={isAboveError}
                              placeholder="Union"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Village
                          </span>
                          <div className="form__form-group-field">
                            <FormField
                              name="village"
                              control={control}
                              component="input"
                              errors={errors}
                              defaultValue={data?.getUserByUserid?.village}
                              rules={{ required: "This is required field" }}
                              isAboveError={isAboveError}
                              placeholder="Village"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Mobile Number
                          </span>
                          <div className="form__form-group-field">
                            <FormField
                              name="mobile"
                              control={control}
                              // component="input"
                              component={renderMaskedField}
                              mask={[
                                "(",
                                "+",
                                "8",
                                "8",
                                ")",
                                " ",
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/,
                                "-",
                                /\d/,
                                /\d/,
                                /\d/,
                                "-",
                                /\d/,
                                /\d/,
                                /\d/,
                              ]}
                              errors={errors}
                              defaultValue={data?.getUserByUserid?.phone}
                              rules={{ required: "This is required field" }}
                              isAboveError={isAboveError}
                              placeholder="(+88) _____ ___ ___"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">Email</span>
                          <div className="form__form-group-field">
                            <FormField
                              name="email"
                              control={control}
                              component="input"
                              type="email"
                              errors={errors}
                              defaultValue={data?.getUserByUserid?.email}
                              rules={{
                                required: "This is a required field",
                                pattern: {
                                  value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                  message: "Please enter a valid email ",
                                },
                              }}
                              isAboveError={isAboveError}
                              placeholder="example@domain.com"
                            />
                            {/* Error Message */}
                          </div>
                        </div>
                      </Col>
                      {
                        <Col md={12}>
                          <div className="form__form-group">
                            <span className="form__form-group-label">
                              Password
                            </span>
                            <div className="form__form-group-field password">
                              <FormField
                                name="password"
                                control={control}
                                component={PasswordField}
                                errors={errors}
                                isAboveError={isAboveError}
                                placeholder="password"
                              />
                            </div>
                          </div>
                        </Col>
                      }
                      <Col md={12}>
                        <h5>
                          <b>Additional information</b>
                        </h5>
                        <hr />
                      </Col>

                      <Col md={12}>
                        {companyCount?.map((item, index) => (
                          <Row key={index}>
                            <Col md={6}>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Company
                                </span>
                                <div className="form__form-group-field">
                                  {roles?.includes("System Admin") ? (
                                    <FormField
                                      // disabled={true}
                                      name={`company`}
                                      control={control}
                                      component={
                                        userID == data?.getUserByUserid?.id
                                          ? SelectFieldDisabled
                                          : SelectField
                                      }
                                      // options={allCompanies}
                                      // defaultValue={allCompanies[0]?.label}
                                      defaultValue={allCompanies?.find(
                                        (item) =>
                                          item?.value ==
                                          data?.getUserByUserid
                                            ?.companyCollaborationInfo[0]
                                            ?.company_id
                                      )}
                                      options={getAvailableCompanies(index)}
                                      // value={getAvailableCompanies(index).find(
                                      //   (comp) => comp.value === item.company
                                      // )}
                                      value={allCompanies?.find(
                                        (item) =>
                                          item?.value ==
                                          data?.getUserByUserid
                                            ?.companyCollaborationInfo[0]
                                            ?.company_id
                                      )}
                                      placeholder="Select company"
                                      // onChange={(e) => {
                                      //   const list = [...companyCount];
                                      //   list[index].company = e.value;
                                      //   setCompanyCount(list);
                                      // }}
                                    />
                                  ) : (
                                    <FormField
                                      name="company"
                                      control={control}
                                      component={
                                        userID == data?.getUserByUserid?.id
                                          ? SelectFieldDisabled
                                          : SelectField
                                      }
                                      options={allCompanies}
                                      defaultValue={allCompanies?.find(
                                        (item) =>
                                          item?.value ==
                                          data?.getUserByUserid
                                            ?.companyCollaborationInfo[0]
                                            ?.company_id
                                      )}
                                      placeholder="Select Company"
                                    />
                                  )}
                                </div>
                                {companyCount[index]?.required ? (
                                  <span
                                    class="form__form-group-error"
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    This is required field
                                  </span>
                                ) : null}
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="form__form-group">
                                <label className="form__form-group-label">
                                  Office ID
                                </label>
                                <div className="form__form-group-field">
                                  <FormField
                                    name="office_id"
                                    component="input"
                                    control={control}
                                    defaultValue={
                                      data?.getUserByUserid?.additionalInfo
                                        ?.office_id || ""
                                    }
                                    type="text"
                                    placeholder="Enter Office Id"
                                    // Set disabled to false so it can be edited
                                    disabled={false}
                                  />
                                </div>
                                {companyCount[index]?.required ? (
                                  <span
                                    class="form__form-group-error"
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    This is required field
                                  </span>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                        ))}
                      </Col>
                      <Col md={6}>
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Joining Date
                          </label>
                          <div className="form__form-group-field joiningCalender">
                            <DatePicker
                              dateFormat="dd/MM/yyyy"
                              selected={joiningDate}
                              placeholderText="dd/mm/yyyy"
                              onChange={handleDateChange}
                              maxDate={new Date()}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Department
                          </span>
                          <div className="form__form-group-field">
                            <Controller
                              name="dept_id"
                              control={control}
                              defaultValue={
                                defaultDepartment
                                  ? {
                                      label: defaultDepartment.name,
                                      value: defaultDepartment.id,
                                    }
                                  : null
                              } // Set defaultValue for update
                              render={({ field }) => (
                                <SelectField
                                  {...field}
                                  options={departmentData?.getAllDepartments?.map(
                                    (department) => ({
                                      label: department?.name,
                                      value: department?.id,
                                    })
                                  )}
                                  placeholder="Select department"
                                  onChange={(selectedOption) => {
                                    field.onChange(selectedOption);
                                    handleDeptChange(selectedOption);

                                    setAndClearFields(
                                      setValue,
                                      "dept_id",
                                      selectedOption,
                                      ["desig_id"]
                                    );
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Designation
                          </span>
                          <div className="form__form-group-field">
                            <FormField
                              name="desig_id"
                              control={control}
                              component={
                                userID == data?.getUserByUserid?.id
                                  ? SelectFieldDisabled
                                  : SelectField
                              }
                              // disabled={
                              //   !selectedDeptId || userID ? true : false
                              // } // Disable if no department is selected or userID is set
                              options={filteredDesignations?.map(
                                (designation) => ({
                                  label: designation?.name,
                                  value: designation?.id,
                                })
                              )}
                              defaultValue={
                                defaultDesignation
                                  ? {
                                      label: defaultDesignation.name,
                                      value: defaultDesignation.id,
                                    }
                                  : null
                              }
                              placeholder="Select designation"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="form__form-group">
                          <label className="form__form-group-label">RFID</label>
                          <div className="form__form-group-field">
                            <FormField
                              name="rfid"
                              component="input"
                              control={control}
                              defaultValue={
                                data?.getUserByUserid?.additionalInfo?.rfid ||
                                ""
                              }
                              type="text"
                              placeholder="Enter RFID"
                              // Set disabled to false so it can be edited
                              disabled={false}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="form__form-group">
                          <label className="form__form-group-label">
                            Description
                          </label>
                          <div className="form__form-group-field">
                            <FormField
                              name="description"
                              component="textarea"
                              control={control}
                              type="text"
                              placeholder={`Enter description`}
                              defaultValue={
                                data?.getUserByUserid?.additionalInfo
                                  ?.description
                              }
                              rules={{
                                maxLength: {
                                  value: 250,
                                  message:
                                    "Description must be less than 250 characters.",
                                },
                                validate: (value) =>
                                  value?.length <= 250 ||
                                  "Description must be less than 250 characters.",
                              }}
                              errors={errors}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Employee Status
                          </span>
                          <div className="form__form-group-field">
                            <FormField
                              name="isActive"
                              control={control}
                              component={SelectField}
                              errors={errors}
                              options={[
                                {
                                  value: true,
                                  label: "Active",
                                },
                                {
                                  value: false,
                                  label: "Inactive",
                                },
                              ]}
                              rules={{ required: "This is required field" }}
                              defaultValue={[
                                {
                                  value: true,
                                  label: "Active",
                                },
                                {
                                  value: false,
                                  label: "Inactive",
                                },
                              ].find(
                                (item) =>
                                  item?.value == data?.getUserByUserid?.isActive
                              )}
                              isAboveError={isAboveError}
                              placeholder="Select Status"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={4}>
                        <Col md={6}>
                          <div className="form__form-group">
                            <span className="form__form-group-label">
                              Job Type
                            </span>
                            <div className="form__form-group-field">
                              <Controller
                                name="jobType"
                                errors={errors}
                                control={control}
                                rules={{ required: "This is required field" }}
                                defaultValue={data?.getUserByUserid?.jobType}
                                render={({ field }) => (
                                  <SelectField
                                    {...field}
                                    options={jobTypeOptions}
                                    placeholder="Select Job Type"
                                    value={jobTypeOptions.find(
                                      (option) => option.value === field.value
                                    )}
                                    onChange={(selectedOption) => {
                                      field.onChange(selectedOption.value);
                                    }}
                                  />
                                )}
                              />
                            </div>
                            {errors.jobType && (
                              <span
                                style={{
                                  color: "red",
                                  fontSize: "0.875em",
                                  marginTop: "0.25em",
                                }}
                              >
                                {errors?.jobType?.message}
                              </span>
                            )}
                          </div>
                        </Col>
                      </Col>
                      <Col md={4}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Employee Classification
                          </span>
                          <div className="form__form-group-field">
                            <Controller
                              name="EmployeeClassification"
                              errors={errors}
                              control={control}
                              rules={{ required: "This is required field" }}
                              defaultValue={
                                data?.getUserByUserid?.EmployeeClassification
                              }
                              render={({ field }) => (
                                <SelectField
                                  {...field}
                                  options={EmployeeClassificationOptions}
                                  placeholder="Select Employee Classification"
                                  value={EmployeeClassificationOptions.find(
                                    (option) => option.value === field.value
                                  )}
                                  onChange={(selectedOption) => {
                                    field.onChange(selectedOption.value);
                                  }}
                                />
                              )}
                            />
                          </div>
                          {errors.EmployeeClassification && (
                            <span
                              style={{
                                color: "red",
                                fontSize: "0.875em",
                                marginTop: "0.25em",
                              }}
                            >
                              {errors?.EmployeeClassification?.message}
                            </span>
                          )}
                        </div>
                      </Col>

                      <Col md={6} lg={4}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Working hours (per day)
                          </span>
                          <div className="form__form-group-field">
                            <Controller
                              name="workingTime"
                              errors={errors}
                              control={control}
                              rules={{ required: "This is required field" }}
                              defaultValue={data?.getUserByUserid?.workingTime}
                              render={({ field }) => (
                                <SelectField
                                  {...field}
                                  options={workingTimeOptions}
                                  placeholder="Select Working Time"
                                  value={workingTimeOptions.find(
                                    (option) => option.value === field.value
                                  )}
                                  // onChange={(selectedOption) =>
                                  //   field.onChange(selectedOption.value)
                                  // }

                                  onChange={(selectedOption) => {
                                    field.onChange(selectedOption.value);
                                  }}
                                />
                              )}
                            />
                          </div>
                          {errors?.workingTime && (
                            <span
                              style={{
                                color: "red",
                                fontSize: "0.875em",
                                marginTop: "0.25em",
                              }}
                            >
                              {errors?.workingTime.message}
                            </span>
                          )}
                        </div>
                      </Col>
                      <Col md={6} lg={4}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Qualification/Specialization
                          </span>
                          <div className="form__form-group-field">
                            <Controller
                              name="qualification"
                              errors={errors}
                              control={control}
                              rules={{ required: "This is required field" }}
                              defaultValue={
                                data?.getUserByUserid?.qualification
                              }
                              render={({ field }) => (
                                <SelectField
                                  {...field}
                                  options={educationOptions}
                                  placeholder="Select Qualification/Specialization"
                                  value={educationOptions.find(
                                    (option) => option.value === field.value
                                  )}
                                  onChange={(selectedOption) => {
                                    field.onChange(selectedOption.value);
                                  }}
                                />
                              )}
                            />
                          </div>
                          {errors?.qualification && (
                            <span
                              style={{
                                color: "red",
                                fontSize: "0.875em",
                                marginTop: "0.25em",
                              }}
                            >
                              {errors?.qualification?.message}
                            </span>
                          )}
                        </div>
                      </Col>
                      {/* file upload */}

                      <Col md={12} lg={4}>
                        <p>
                          Upload Documents:{" "}
                          <span style={{ color: "gray", fontSize: "12px" }}>
                            PNG, JPG, JPEG, PDF
                          </span>
                        </p>
                        <div
                          style={{
                            display: "flex",
                            height: "auto",
                            flexDirection: "column",
                            maxWidth: "600px",
                            margin: "0 auto",
                            padding: "5px",
                            border: "1px solid #ddd",
                            borderRadius: "8px",
                            backgroundColor: "#fff",
                          }}
                        >
                          <Row>
                            <Col md={5}>
                              <div
                                style={{
                                  width: "100%",
                                  border: "2px dashed #0d6efd",
                                  borderRadius: "8px",
                                  padding: "5px",
                                  textAlign: "center",
                                  backgroundColor: "#fff",
                                  marginBottom: "20px",
                                }}
                              >
                                <input
                                  {...register("document")}
                                  name="document"
                                  type="file"
                                  multiple
                                  accept=".pdf, .jpg, .jpeg, .png"
                                  onChange={handleFileChange}
                                  style={{ display: "none" }}
                                  id="file-input"
                                />
                                <label
                                  htmlFor="file-input"
                                  style={{ cursor: "pointer" }}
                                >
                                  <UploadFileIcon
                                    style={{ fontSize: "40px", color: "#000" }}
                                  />
                                </label>
                              </div>
                            </Col>
                            <Col md={7}>
                              <div style={{ width: "100%" }}>
                                <h5 style={{ marginBottom: "10px" }}>
                                  Documents
                                </h5>
                                <ul
                                  style={{
                                    listStyleType: "none",
                                    padding: "5px",
                                  }}
                                >
                                  {files?.length > 0 &&
                                    files.map((file, index) => {
                                      // Determine the file extension
                                      const fileName = file.name || file;
                                      const fileExtension = fileName
                                        .split(".")
                                        .pop()
                                        .toLowerCase();

                                      // Determine the file URL (for new files and previously uploaded files)
                                      const fileURL =
                                        file.url ||
                                        (file instanceof File
                                          ? URL.createObjectURL(file)
                                          : `${imageUrl}/documents/${file}`);

                                      return (
                                        <li
                                          key={index}
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            borderBottom: "1px solid #ddd",
                                            padding: "8px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              height: "100px",
                                              width: "100px",
                                            }}
                                          >
                                            {fileExtension === "pdf" ? (
                                              // PDF preview
                                              <embed
                                                src={fileURL}
                                                type="application/pdf"
                                                style={{
                                                  width: "100%",
                                                  height: "100%",
                                                }}
                                              />
                                            ) : (
                                              <img
                                                src={fileURL}
                                                alt="preview"
                                                style={{
                                                  width: "100%",
                                                  height: "100%",
                                                  objectFit: "cover",
                                                }}
                                              />
                                            )}
                                          </div>

                                          <button
                                            type="button"
                                            onClick={() =>
                                              handleRemoveFile(fileName)
                                            }
                                            style={{
                                              backgroundColor: "transparent",
                                              color: "red",
                                              border: "none",
                                              cursor: "pointer",
                                              fontSize: "16px",
                                            }}
                                          >
                                            X
                                          </button>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>

                      {/* file upload end */}
                      <Col md={12}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            marginTop: "10px",
                          }}
                        >
                          <ButtonToolbar className="form__button-toolbar">
                            <Button
                              onClick={() => history.push("/user/list")}
                              type="button"
                            >
                              Cancel
                            </Button>
                            {createUserFeedback?.loading ||
                            createCompanyCollaborationFeedback?.loading ||
                            updateUserFeedback?.loading ||
                            updateUserAdditionalInfoFeedback?.loading ||
                            createUserAdditionalInfoFeedback?.loading ? (
                              <>
                                <span
                                  className="spinner-border spinner-border-sm mr-2"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                {updateUserInfo?.id
                                  ? "Updating..."
                                  : "Creating..."}{" "}
                              </>
                            ) : documentLoading ? (
                              <>
                                <span
                                  className="spinner-border spinner-border-sm mr-2"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                {"  updating..."}
                              </>
                            ) : (
                              <Button
                                color="primary"
                                type="submit"
                                disabled={isLoading} // Disable when loading
                              >
                                {updateUserInfo?.id ? "Update" : "Create"}{" "}
                                {/* {documentLoading ? "Document Uploading..." : ""} */}
                              </Button>
                            )}
                          </ButtonToolbar>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </form>
              </CardBody>
            )}
          </Card>
        </Col>
      }
    </div>
  );
};

export default CreateUser;
