import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import FirebaseIcon from "mdi-react/FirebaseIcon";
import withAuthFirebase from "@/shared/components/auth/withAuthFirebase";

import LogInFormPhoto from "@/shared/components/loginPhotoForm/LogInFormPhoto";
import showResults from "@/utils/showResults";
import GoogleAuthBtn from "../AuthBtn/googleAuthBtn";
import MicrosoftAuthBtn from "../AuthBtn/microsoftAuthBtn";
import { login as loginAuth0 } from "../../../shared/components/auth/withAuth0";
import CustomerLoginForm from "./CustomerLoginForm";

const auth0Icon = `${process.env.PUBLIC_URL}/img/auth0.svg`;

const CustomerLogin = ({ changeIsOpenModalFireBase }) => (
  <>
    <div className="account__card">
      <div className="account__head">
        <h3 className="account__title">Welcome</h3>
      </div>
    </div>
    <CustomerLoginForm />
  </>
);

CustomerLogin.propTypes = {
  changeIsOpenModalFireBase: PropTypes.func.isRequired,
};

export default withAuthFirebase(CustomerLogin);
